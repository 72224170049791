export default [
  {
    path: '/channels',
    name: 'channels',
    component: () => import(/* webpackChunkName: "channels" */ '../../views/Channels/Channels.vue'),
    meta: {
      title: 'Каналы',
    },
  },
];
