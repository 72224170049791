import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async getAll(payload) {
    const { data } = await http.get('/api/v1/news', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async add(payload) {
    const { data } = await http.post('/api/v1/news', payload, { withLoader: false });
    return data;
  },

  async getOneNews(id) {
    const { data } = await http.get(`/api/v1/news/${id}`, { withLoader: false });
    return data;
  },

  async edit(id, payload) {
    const { data } = await http.patch(`/api/v1/news/${id}`, payload, { withLoader: false });
    return data;
  },

  async delete(id) {
    const { data } = await http.delete(`/api/v1/news/${id}`, { withLoader: false });
    return data;
  },

  // async publish(id) {
  //   const { data } = await http.post(`/api/v1/news/${id}/publish`,null, { withLoader: false });
  //   return data;
  // },
  //
  // async unpublish(id) {
  //   const { data } = await http.post(`/api/v1/news/${id}/unpublish`, null, { withLoader: false });
  //   return data;
  // },
};
