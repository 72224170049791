import { http } from '@/plugins/axios';

export default {
  state: () => ({
    enums: null,
  }),

  mutations: {
    SET_ENUMS(state, data) {
      state.enums = data;
    },
  },

  actions: {
    async getEnums({ commit, state }) {
      if (state.enums) {
        return;
      }

      const { data } = await http.get('/api/v1/directories', { withLoader: false });

      if (data) {
        commit('SET_ENUMS', data.data);
      } else {
        console.warn('Не удалось загрузить enums (directories) приложения.');
      }
    },
  },

  getters: {
    enumByName: (state) => (name) => state.enums ? state.enums[name] : [],
  },
};

