<template>
  <div class="search">
    <input
      ref="searchInput"
      :placeholder="placeholder"
      :value="modelValue"
      class="search__input"
      type="search"
      @keyup.enter.stop="setValue"
      :autocomplete="autocomplete"
    >
    <button
      class="btn search__btn search__btn--submit"
      @click="setValue"
    >
      <BaseIcon
        class="btn-icon"
        name="search"
      />
    </button>
  </div>
</template>

<script setup>
import BaseIcon from '@/components/base/BaseIcon';
import { ref } from 'vue';
import { fromEvent, useSubscription } from '@vueuse/rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs';

// eslint-disable-next-line no-undef
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: 'Поиск по идентификатору, почте, номеру телефона, ФИО',
  },
  autocomplete: {
    type: String,
    default: 'off',
  },
  minSearchLength: {
    type: Number,
    default: 3,
  },
  debounceTimeMs: {
    type: Number,
    default: 500,
  },
});

// eslint-disable-next-line no-undef
const emit = defineEmits(['update:modelValue', 'search']);

const searchInput = ref(null);
const setValue = () => {
  emit('update:modelValue', searchInput.value.value);
  emit('search', searchInput.value.value);
};

useSubscription(
  fromEvent(searchInput, 'input')
    .pipe(
      map(e => e?.target?.value),
      filter(v => v.length >= props.minSearchLength || v === ''),
      debounceTime(props.debounceTimeMs),
      distinctUntilChanged(),
    ).subscribe(() => {
      setValue();
    }),
);

</script>
