<!-- eslint-disable vue/no-template-shadow -->
<template>
  <Drawer
    :expanded="expanded"
    :items="items"
    :mini="true"
    item="CustomItem"
    mode="push"
    position="start"
    @select="onSelect"
  >
    <template #CustomItem="{ props }">
      <transition name="slide-fade">
        <a
          :href="props?.data?.path"
          @click.prevent
        >
          <DrawerItem
            v-if="props.visible !== false"
            :class="{ 'shadow-menu': props.expanded, 'drawer-item-bg': props.parentId }"
            :style="(props.expanded !== undefined) ? 'color: inherit;': ''"
            class="text-sm pt-0 pb-0"
            v-bind="props"
            @click="props.onClick"
          >
            <span
              v-tooltip.top-start=" { content: props.text, delay: 500, disabled: expanded }"
              :class="'k-icon ' + props.icon"
            >
              <span
                v-if="!!countGroupNewItems(props.id) || (!expanded && props.notifications) || (expanded && !props.parentId && !!countGroupNewItems(props.id))"
              >
                <Badge
                  :rounded="'full'"
                  :theme-color="'primary'"
                  position="outside"
                  size="small"
                />
              </span>
            </span>
            <div class="d-flex items-center">
              <span
                class="k-item-text"
              >{{ props.text }}
                <KStatusTag
                  v-if="props.notifications"
                  :message="props.notifications.toString()"
                  class="inline-block rounded-full tag-min-width text-center ml-1"
                  color="primary"
                />
              </span>
            </div>
            <span
              v-if="props.expanded !== undefined"
              :class="props.expanded ? downClass : rightClass"
              :style="{
                position: 'absolute',
                right: '10px',
                visibility: expanded ? '' : 'hidden',
              }"
            ></span>
          </DrawerItem>
        </a>
      </transition>
    </template>
    <DrawerContent>
      <div class="p-4">
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component"/>
          </transition>
        </router-view>
      </div>
    </DrawerContent>
  </Drawer>
</template>

<script setup>
import { Drawer, DrawerContent, DrawerItem } from '@progress/kendo-vue-layout';
import KStatusTag from '@/components/kendo-wrappers/KStatusTag.vue';
import { Badge } from '@progress/kendo-vue-indicators';
import { useDrawerMenu } from '@/assets/js/drawer/useDrawerMenu';
import { computed, onBeforeMount, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useEventBus } from '@vueuse/core';
import { useSocketRoom } from '@/compositions/socket/useSocketRoom';
import { useStore } from 'vuex';

// eslint-disable-next-line no-undef,no-unused-vars
const props = defineProps({
  expanded: {
    type: Boolean,
    default: true,
  },
});

const {
  items,
  menu,
  onSelect,
  updateRecords,
  menuMapping,
  countGroupNewItems,
  getNewRecords,
  selectActiveRoute,
} = useDrawerMenu();

const store = useStore();
const route = useRoute();
const downClass = 'k-icon k-i-arrow-60-down';
const rightClass = 'k-icon k-i-arrow-60-right';
const user = computed(() => {
  return store.getters.user;
});

onBeforeMount( () => getNewRecords());

watch(() => route.path,
      () => {
        selectActiveRoute();
      },
      { deep: true },
);

watch(() => user.value,
      async (val) => {
        if (val) {
          await getNewRecords();
        }
      },
);

const { on } = useEventBus('bus');

// Ожидаем ивент по шине обновления одного пункта меню {type: 'имя_раздела'}
on(_event => {
  const item = menu.items.find(i => i.text === menuMapping[_event.type]);
  if (item.notifications > 0) {
    menu.findItem(item.id).setNotifications(item.notifications - 1);
    items.value = [...menu.items];
  }
});

//  комната private.admin.new-record события new-record.create
//  при создании и изменении объектов Moderation, Verification, VideoReport, CopyrightReport
useSocketRoom('private.admin.new-record', {
  'new-record.create': updateRecords,
});

</script>

<style scoped>
.k-drawer-item:focus {
  box-shadow: unset;
  background: unset;
}

.slide-fade-enter-active {
  transition: all 0.25s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.25s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-1rem);
  opacity: 0;
}

::v-deep(.k-badge-outside.k-top-end) {
  transform: translate(110%, 40%);
}

.tag-min-width {
  min-width: calc(1em + 0.7em + 2px)
}

.shadow-menu {
  box-shadow: 0 2px 4px #cccccc;
  z-index: 1;
}

.drawer-item-bg {
  background: #F7FAFF;
}

::v-deep(.k-badge-outside.k-top-end) {
  transform: translate(110%, 40%);
}
</style>