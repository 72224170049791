import { http } from '@/plugins/axios';

export default {
  /*
   * search - string
   * page - integer
   * limit - integer
   */
  async getAll(payload) {
    const { data } = await http.get(
      'https://jsonplaceholder.typicode.com/albums/1/photos',
    );
    return data;
  },

  /*
   * id - integer
   */
  async getActive(id) {
    const { data } = await http.get(`api/v1/accounts/${id}`);
    return data;
  },

  /*
   * id - integer
   */
  async deleteUser(id) {
    const { data } = await http.delete(`api/v1/accounts/${id}`);
    return data;
  },

  /*
   * id - integer
   */
  async enableUser(id) {
    const { data } = await http.post(`/api/v1/accounts/${id}/enable`);
    return data;
  },

  /*
   * id - integer
   */
  async disableUser(id) {
    const { data } = await http.post(`api/v1/accounts/${id}/disable`);
    return data;
  },

  /*
          email - string*
          login - string*
          first_name - string
          last_name - string
          avatar - file
        */
  async addFakeAccount(payload) {
    return await http.post('api/v1/accounts/fakes/create', payload);
  },

  async editFakeAccount(userId, payload) {
    const { data } = await http.patch(`api/v1/accounts/${userId}`, payload);
    return data;
  },

  /*
   * email - string
   */

  async transferAccount(userId, payload) {
    const { data } = await http.post(
      `api/v1/accounts/fakes/${userId}/send-link`,
      payload,
    );
    return data;
  },
};
