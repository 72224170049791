export default {
  state: () => ({
    IdPlaylistsWrapperHeight: null,
    IdChannelsWrapperHeight: null,
    IdVideosWrapperHeight: null,
  }),

  getters: {
    getIdPlaylistsWrapperHeight(state) {
      return state.IdPlaylistsWrapperHeight;
    },
    getIdChannelsWrapperHeight(state) {
      return state.IdChannelsWrapperHeight;
    },
    getIdVideosWrapperHeight(state) {
      return state.IdVideosWrapperHeight;
    },
  },

  mutations: {
    SET_ID_PLAYLISTS_WRAPPER_HEIGHT(state, height) {
      state.IdPlaylistsWrapperHeight = height;
    },
    SET_ID_CHANNELS_WRAPPER_HEIGHT(state, height) {
      state.getIdChannelsWrapperHeight = height;
    },
    SET_ID_VIDEOS_WRAPPER_HEIGHT(state, height) {
      state.getIdVideosWrapperHeight = height;
    },
  },
};
