import history from '@/plugins/history';
import router from '@/router';

export default {
  state: () => ({
    pages: null,
  }),

  getters: {
    getPages(state) {
      return state.pages;
    },
  },

  mutations: {
    SET_PAGES(state, meta) {
      if (meta.current_page > meta.last_page) {
        router.push({ name: '404' });
      } else {
        state.pages = meta;
        history.addParamsToLocation({ page: meta.current_page === 1 ? null : meta.current_page });
      }
    },
  },
};
