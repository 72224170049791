import { http } from '@/plugins/axios';

export default {
  async getServersPay(payload) {
    const { data } = await http.get('api/v1/servers/payments', { params: payload });
    return data;
  },
  async postServersPay(payload) {
    const { data } = await http.post('api/v1/servers/payments', payload);
    return data;
  },
  async postServersPayId(payload) {
    const { data } = await http.get(`api/v1/servers/payments/${payload}`, payload);
    return data;
  },
  async putServersPayId(payload, form) {
    const { data } = await http.put(`api/v1/servers/payments/${payload}`, form);
    return data;
  },
  async getServersPayMethods() {
    const { data } = await http.get('api/v1/servers/payments/methods');
    return data;
  },
  async postServersPayMethods(payload) {
    const { data } = await http.post('api/v1/servers/payments/methods', payload);
    return data;
  },
  async getServersPayWallets() {
    const { data } = await http.get('api/v1/servers/payments/wallets');
    return data;
  },
  async postServersPayWallets(payload) {
    const { data } = await http.post('api/v1/servers/payments/wallets', payload);
    return data;
  },
};