import { http } from '@/plugins/axios';
import qs from 'qs';
// import qs from 'qs';

export default {
  async getDirectories() {
    const { data } = await http.get('api/v1/directories');
    return data;
  },
  async getAllServers() {
    const { data } = await http.get('api/v1/servers');
    return data;
  },
  async getServers(payload) {
    const { data } = await http.get('api/v1/servers', { params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      } });
    return data;
  },
  async putServers(payload, form) {
    const { data } = await http.put(`api/v1/servers/${payload}`, form);
    return data;
  },
  async getServersId(payload) {
    const { data } = await http.get(`api/v1/servers/${payload}`);
    return data;
  },
  async postServers(payload) {
    const { data } = await http.post('api/v1/servers', payload);
    return data;
  },
  async getHostings() {
    const { data } = await http.get('api/v1/servers/hostings');
    return data;
  },
  async postHostings(payload) {
    const { data } = await http.post('api/v1/servers/hostings', payload);
    return data;
  },
  async postGroups(payload) {
    const { data } = await http.post('api/v1/servers/groups', payload);
    return data;
  },
  async getGroups() {
    const { data } = await http.get('api/v1/servers/groups');
    return data;
  },
  async updateGroups(group, payload) {
    const { data } = await http.post(`api/v1/servers/groups/${group}/update-servers`, { servers: payload });
    return data;
  },
  async postAssignments(payload) {
    const { data } = await http.post('api/v1/servers/assignments', payload);
    return data;
  },
  async getAssignments() {
    const { data } = await http.get('api/v1/servers/assignments');
    return data;
  },
  async postLancards(payload) {
    const { data } = await http.post('api/v1/servers/lan-cards', payload);
    return data;
  },
  async postRam(payload) {
    const { data } = await http.post('api/v1/servers/ram', payload);
    return data;
  },
  async postCpu(payload) {
    const { data } = await http.post('api/v1/servers/cpu', payload);
    return data;
  },
  async postDisk(payload) {
    const { data } = await http.post('api/v1/servers/disks', payload);
    return data;
  },
  async getLancards() {
    const { data } = await http.get('api/v1/servers/lan-cards');
    return data;
  },
  async getTotalAmount() {
    const { data } = await http.get('api/v1/servers/payments/total-amount');
    return data;
  },
  async getRam() {
    const { data } = await http.get('api/v1/servers/ram');
    return data;
  },
  async getDisk() {
    const { data } = await http.get('/api/v1/servers/disks');
    return data;
  },
  async getCpu() {
    const { data } = await http.get('/api/v1/servers/cpu');
    return data;
  },

  async getOvnmediaServers() {
    const { data } = await http.get('/api/v1/servers/ovnmedia');
    return data;
  },

  async updateOvnmediaServer(id, payload) {
    const { data } = await http.put(`/api/v1/servers/ovnmedia/${id}`, payload);
    return data;
  },

  async createOvnmediaServer(payload) {
    const { data } = await http.post('/api/v1/servers/ovnmedia/', payload);
    return data;
  },

  async deleteOvnmediaServer(id) {
    const { data } = await http.delete(`/api/v1/servers/ovnmedia/${id}`);
    return data;
  },
};