export default [
  {
    path: '/access',
    component: () => import(/* webpackChunkName: "access" */ '../../views/Access/AccessList'),
    name: 'access',
    meta: {
      title: 'Выдача доступов',
    },
  },
];
