import { http } from '@/plugins/axios';
import qs from 'qs';

const path = '/api/v1/banned/domain';

export default {
  async getAll(payload) {
    const { data } = await http.get(`${path}/`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async getOne(domain) {
    const { data } = await http.get(`${path}/${domain}`);
    return data;
  },

  async insertOne(server_name) {
    const { data } = await http.post(`${path}/`, {
      server_name: server_name,
    });
    return data;
  },

  async updateOne(id, newName = '') {
    const { data } = await http.put(`${path}/${id}`, {
      server_name: newName,
    });
    return data;
  },

  async delete(ids) {
    const { data } = await http.delete(`${path}/`, {
      params: {
        id: ids,
      },
    });
    return data;
  },
};