import { http } from '@/plugins/axios';
import qs from 'qs';

const path = '/api/v1/blocking-reasons';
export default {
  async getAll(payload) {
    const { data } = await http.get(`${path}`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getOne(id, payload) {
    const { data } = await http.get(`${path}/${id}`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async postTemplate(payload) {
    const { data } = await http.post(`${path}`, payload);
    return data;
  },

  async updateTemplate(id, payload) {
    const { data } = await http.put(`${path}/${id}`, payload);
    return data;
  },

  async deleteTemplate(id) {
    const { data } = await http.delete(`${path}/${id}`);
    return data;
  },
};
