const NotificationStatus = [
  {
    'value': 'read',
    'text': 'Прочитано',
  },
  {
    'value': 'unread',
    'text': 'Не прочитано',
  },
];

export default NotificationStatus;