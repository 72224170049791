import config from '@/config';

export default {
  state: () => ({
    activeMenu: {
      profile: false,
      moderatorFilter: false,
      sort: {
        status: {
          title: 'Статус',
          active: false,
          menu: Object.keys(config.statusesVerified).map(key => {
            return {
              id: key,
              title: config.statusesVerified[key].title,
              icon: config.statusesVerified[key].icon,
            };
          }),
        },
      },
      accessMenu: {
        role: {
          active: false,
          menu: [
            {
              id: 0,
              title: 'Модератор',
            }, {
              id: 1,
              title: 'Администратор',
            },
          ],
        },
      },
      accessCard: false,
      select: false,
      complains: {
        date: {
          title: 'Дата получения',
          active: false,
          menu: [
            { title: '27.04.2022' },
            { title: '27.04.2022' },
            { title: '27.04.2022' },
          ],
        },
        category: {
          title: 'Категория',
          active: false,
          menu: [{ title: 'Авторизация' }, { title: 'Регистрация' }],
        },
        status: {
          title: 'Статус',
          active: false,
          menu: [
            {
              title: 'Все',
            }, {
              title: 'Проверяется',
              icon: 'status-1',
            }, {
              title: 'Пройдена',
              icon: 'status-2',
            }, {
              title: 'Не решено',
              icon: 'status-0',
            }, {
              title: 'Получено',
              icon: 'status-4',
            },
          ],
        },
      },
      verify: {
        date: {
          title: 'Дата получения',
          active: false,
          menu: [
            { title: '27.04.2022' },
            { title: '27.04.2022' },
            { title: '27.04.2022' },
          ],
        },
        name: {
          title: 'Имя аккаунта',
          active: false,
          menu: [{ title: 'По возрастанию' }, { title: 'По убыванию' }],
        },
        status: {
          title: 'Статус',
          active: false,
          menu: [
            {
              title: 'Все',
            }, {
              title: 'Проверяется',
              icon: 'status-1',
            }, {
              title: 'Пройдена',
              icon: 'status-2',
            }, {
              title: 'Не решено',
              icon: 'status-0',
            }, {
              title: 'Получено',
              icon: 'status-4',
            },
          ],
        },
      },
      files: {
        date: {
          title: 'Дата загрузки',
          active: false,
          menu: [
            {
              title: 'По убыванию',
              icon: 'sort',
              field: 'created_at',
              direction: 'desc',
            },
            {
              title: 'По возрастанию',
              icon: 'sort-up',
              field: 'created_at',
              direction: 'asc',
            },
          ],
        },
      },
      category: {
        more: {
          id: null,
          active: false,
          menu: [
            {
              title: 'Редактировать',
              icon: 'edit',
              color: '#5985A6',
              action: 'edit',
            }, {
              title: 'Удалить',
              icon: 'delete-outline',
              color: '#E6676E',
              action: 'delete',
            },
          ],
        },
      },
      news: {
        more: {
          id: null,
          active: false,
          menu: [
            {
              title: 'Редактировать',
              icon: 'edit',
              color: '#5985A6',
              action: 'edit',
            }, {
              title: 'Удалить',
              icon: 'delete-outline',
              color: '#E6676E',
              action: 'delete',
            },
          ],
        },
      },
      downloads: {
        statuses: {
          title: 'Статус',
          active: false,
          menu: [
            {
              title: 'Все',
            }, {
              title: 'В работе',
              icon: 'status-1',
            }, {
              title: 'Закрыто',
              icon: 'status-2',
            }, {
              title: 'Не решено',
              icon: 'status-0',
            }, {
              title: 'Обрабатывается',
              icon: 'status-0',
            }, {
              title: 'Получено',
              icon: 'status-4',
            },
          ],
        },
        date: {
          title: 'По дате',
          active: false,
          menu: [
            { title: '22.20.2023' },
            { title: '22.20.2023' },
            { title: '22.20.2023' },
          ],
        },
        processingTime: {
          title: 'Время обработки',
          active: false,
          menu: [
            {
              title: 'По убыванию',
              icon: 'sort',
              field: 'created_at',
              direction: 'desc',
            },
            {
              title: 'По возрастанию',
              icon: 'sort-up',
              field: 'created_at',
              direction: 'asc',
            },
          ],
        },
      },
      analytics: {
        name: {
          title: 'Канал',
          active: false,
          menu: [
            {
              title: 'По возрастанию',
              type: 'asc',
            }, {
              title: 'По убыванию',
              type: 'desc',
            },
          ],
        },
        ctr: {
          title: 'CTR',
          active: false,
          menu: [
            {
              title: 'По возрастанию',
              type: 'asc',
            }, {
              title: 'По убыванию',
              type: 'desc',
            },
          ],
        },
        watches: {
          title: 'Досмотры, мин.',
          active: false,
          menu: [
            {
              title: 'По возрастанию',
              type: 'asc',
            }, {
              title: 'По убыванию',
              type: 'desc',
            },
          ],
        },
        profit: {
          title: 'Прибыль, руб.',
          active: false,
          menu: [
            {
              title: 'По возрастанию',
              type: 'asc',
            }, {
              title: 'По убыванию',
              type: 'desc',
            },
          ],
        },
        typeOfAd: {
          title: 'Тип рекламы',
          active: false,
          menu: [
            {
              id: 0,
              title: 'Пре-ролл',
              type: 'filter',
            }, {
              id: 1,
              title: 'Мид-ролл',
              type: 'filter',
            }, {
              id: 2,
              title: 'Пост-ролл',
              type: 'filter',
            }, {
              id: 3,
              title: 'Все',
              type: 'filter',
            },
          ],
        },
      },
    },
  }),

  getters: {
    getProfileMenu(state) {
      return state.activeMenu.profile;
    },
    getModeratorMenu(state) {
      return state.activeMenu.moderatorFilter;
    },
    getSortMenu(state) {
      return state.activeMenu.sort;
    },
    getAccessCard(state) {
      return state.activeMenu.accessCard;
    },
    getAccessMenu(state) {
      return state.activeMenu.accessMenu;
    },
    getComplainsMenu(state) {
      return state.activeMenu.complains;
    },
    getVerifyMenu(state) {
      return state.activeMenu.verify;
    },
    getFilesMenu(state) {
      return state.activeMenu.files;
    },
    getCategoryMenu(state) {
      return state.activeMenu.category;
    },
    getNewsMenu(state) {
      return state.activeMenu.news;
    },
    getDownloadsMenu(state) {
      return state.activeMenu.downloads;
    },
    getSelectMenu(state) {
      return state.activeMenu.select;
    },
    getAnalyticsMenu(state) {
      return state.activeMenu.analytics;
    },
  },

  mutations: {
    SET_PROFILE_MENU(state) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.profile = !state.activeMenu.profile;
    },
    SET_SELECT_MENU(state) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.select = !state.activeMenu.select;
    },
    SET_SORT_MENU(state, key) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.sort[key].active = !state.activeMenu.sort[key].active;
    },
    SET_ACCESS_MENU(state, key) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.accessMenu[key].active = !state.activeMenu.accessMenu[key].active;
    },
    SET_ACCESS_CARD_MENU(state) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.accessCard = true;
    },
    SET_COMPLAINS_MENU(state, key) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.complains[key].active = !state.activeMenu.complains[key].active;
    },
    SET_VERIFY_MENU(state, name) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.verify[name].active = !state.activeMenu.verify[name].active;
    },
    SET_FILES_MENU(state, name) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.files[name].active = !state.activeMenu.files[name].active;
    },
    SET_CATEGORY_MENU(state, {
      name, id,
    }) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.category[name].active = !state.activeMenu.category[name].active;
      state.activeMenu.category[name].id = id;
    },
    SET_NEWS_MENU(state, {
      name, id,
    }) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.news[name].active = !state.activeMenu.news[name].active;
      state.activeMenu.news[name].id = id;
    },
    SET_DOWNLOADS_MENU(state, name) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.downloads[name].active = !state.activeMenu.downloads[name].active;
    },
    SET_ANALYTICS_MENU(state, name) {
      this.commit('UNSET_ALL_MENU');
      state.activeMenu.analytics[name].active = !state.activeMenu.analytics[name].active;
    },
    TOGGLE_SORT_MENU(state, name) {
      state.activeMenu.sort[name].active = !state.activeMenu.sort[name].active;
    },
    TOGGLE_PROFILE_MENU(state) {
      state.activeMenu.profile = !state.activeMenu.profile;
    },
    TOGGLE_MODERATOR_MENU(state) {
      state.activeMenu.moderatorFilter = !state.activeMenu.moderatorFilter;
    },
    TOGGLE_ACCESS_MENU(state, name) {
      state.activeMenu.accessMenu[name].active = !state.activeMenu.accessMenu[name].active;
    },
    TOGGLE_ACCESS_CARD_MENU(state) {
      state.activeMenu.accessCard = !state.activeMenu.accessCard;
    },
    TOGGLE_SELECT_MENU(state) {
      state.activeMenu.select = !state.activeMenu.select;
    },
    TOGGLE_FILES_MENU(state, name) {
      state.activeMenu.files[name].active = !state.activeMenu.files[name].active;
    },
    TOGGLE_COMPLAINS_MENU(state, name) {
      state.activeMenu.complains[name].active = !state.activeMenu.complains[name].active;
    },
    TOGGLE_VERIFY_MENU(state, name) {
      state.activeMenu.verify[name].active = !state.activeMenu.verify[name].active;
    },
    TOGGLE_CATEGORY_MENU(state, name) {
      state.activeMenu.category[name].active = !state.activeMenu.category[name].active;
    },
    TOGGLE_NEWS_MENU(state, name) {
      state.activeMenu.news[name].active = !state.activeMenu.news[name].active;
    },
    TOGGLE_DOWNLOADS_MENU(state, name) {
      state.activeMenu.downloads[name].active = !state.activeMenu.downloads[name].active;
    },
    TOGGLE_ANALYTICS_MENU(state, name) {
      state.activeMenu.analytics[name].active = !state.activeMenu.analytics[name].active;
    },
    UNSET_ACCESS_CARD_MENU(state) {
      state.activeMenu.accessCard = false;
    },
    UNSET_COMPLAINS_MENU(state, name) {
      state.activeMenu.complains[name] = false;
    },

    UNSET_ALL_MENU(state) {
      for (let el in state.activeMenu) {
        if (Object.values(state.activeMenu[el]).length) {
          Object.keys(state.activeMenu[el]).forEach((m) => {
            state.activeMenu[el][m].active = false;
          });
        } else {
          state.activeMenu[el] = false;
        }
      }
    },
  },
};
