import { http } from '@/plugins/axios';
import qs from 'qs';

const path = '/api/v1/categories';

export default {
  async getAll(payload) {
    const { data } = await http.get(path, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getAllShort(payload) {
    const { data } = await http.get('/api/v2/categories', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async search(payload) {
    const { data } = await http.get('/api/v1/categories/search', {
      params: payload,
      withLoader: false,
    });
    return data;
  },

  async getOne(id) {
    const { data } = await http.get(`${path}/${id}`);
    return data;
  },

  async add(payload) {
    const { data } = await http.post(path, payload);
    return data;
  },

  async edit(id, payload) {
    const { data } = await http.patch(
      `${path}/${id}`,
      payload,
    );
    return data;
  },

  async delete(id) {
    const { data } = await http.delete(`${path}/${id}`);
    return data;
  },
};
