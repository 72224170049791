import serversRepository from '@/repositories/serversRepository';
import paymentsRepository from '@/repositories/paymentsRepository';
// import { dateFormat } from '@/plugins/functions';
// import { parseISO } from 'date-fns';

export default {
  state: () => ({
    selectOptionsBandwidth: null,
    servers: [],
    transactions: [],
    search: null,
    page: 1,
    per_page: 10,
    togglePayment: false,
    pageTransactions: 1,
    active: null,
    unactive: null,
    payment: null,
  }),

  getters: {
    getOptionsBandwidth(state) {
      return state.selectOptionsBandwidth;
    },
    getServersArr(state) {
      return state.servers;
    },
    getTransactionsArr(state) {
      return state.transactions;
    },
    getPage(state) {
      return state.page;
    },
    getActiveFilter(state) {
      return state.active;
    },
    getUnactiveFilter(state) {
      return state.unactive;
    },
    getPaymentFilter(state) {
      return state.payment;
    },
  },

  mutations: {
    SET_SEARCH(state, res) {
      state.servers = [];
      state.search = res ? res : null;
    },
    SET_DIRECTORIES_BANDWIDTH(state, res) {
      state.selectOptionsBandwidth = res ? res : null;
    },
    SET_SERVERS(state, res) {
      state.servers = res ? res : [];
    },
    SET_SERVERS_LOAD(state, res) {
      if (state.servers) {
        state.servers = [...state.servers, ...res];
      } else {
        state.servers = res ? res : [];
      }
    },
    SET_TRANSACTIONS(state, res) {
      if (state.transactions) {
        state.transactions = [...state.transactions, ...res];
      } else {
        state.transactions = res ? res : [];
      }
    },
    SET_PAGE(state) {
      // state.page = Math.floor(state.page + 10);
      state.page++;
    },
    SET_PAGE_TRANSACTIONS(state) {
      // state.pageTransactions = Math.floor(state.pageTransactions + 10);
      state.pageTransactions++;
    },
    SET_ACTIVE_FILTER(state, res) {
      state.active = res;
    },
    SET_UNACTIVE_FILTER(state, res) {
      state.unactive = res;
    },
    SET_PAYMENT_FILTER(state, res) {
      state.payment = res;
    },
    // SET_RESET_FILTERS(state) {
    //   state.active = null;
    //   state.unactive = null;
    //   state.payment = null;
    // },
  },

  actions: {
    async getDirectories() {
      try {
        const res = await serversRepository.getDirectories();
        if (res.data) {
          if (res.data.ServerBandwidth) {
            this.commit('SET_DIRECTORIES_BANDWIDTH', res.data.ServerBandwidth);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getServers({ commit, state }) {
      if (state.search && state.search.length) {
        state.page = 1;
        const res = await serversRepository.getServers({ page: state.page,
          per_page: 10,
          search: state.search });
        if (res.data?.length) {
          state.servers = null;
          this.commit('SET_SERVERS', res.data);
        }
      } else {
        const res = await serversRepository.getServers({ page: state.page,
          per_page: 10 });
        if (res.data?.length) {
          this.commit('SET_SERVERS_LOAD', res.data);
        }
      }
    },

    async getServersFilters({ commit, state }, filter, type) {
      if (filter === 'payment') {
        state.togglePayment = !state.togglePayment;

        if (state.togglePayment) {
          let params = {
            page: state.page,
            per_page: 10,
            sort: {
              field: 'payment_date',
              direction: 'asc',
            },
          };
          const { data } = await serversRepository.getServers(params);
          this.commit('SET_SERVERS', data);
        } else {
          const { data } = await serversRepository.getServers({ page: state.page,
            per_page: 10 });
          this.commit('SET_SERVERS', data);
        }
        this.commit('SET_PAYMENT_FILTER', true);
      } else if (filter?.filter === 'payment_date') {
        const res = await serversRepository.getServers({ page: state.page,
          per_page: 10,
          sort: { filed: 'payment_date',
            direction: filter.type } });
        this.commit('SET_SERVERS', res.data);
      } else {
        const res = await serversRepository.getServers({ page: state.page,
          per_page: 10 });
        if (res.data?.length) {
          if (filter === 'active') {
            this.commit('SET_SERVERS', res.data.filter((item) => item.is_active === true));
            this.commit('SET_UNACTIVE_FILTER', null);
            this.commit('SET_ACTIVE_FILTER', true);
            this.commit('SET_PAYMENT_FILTER', null);
          } else if (filter === 'unactive') {
            this.commit('SET_SERVERS', res.data.filter((item) => item.is_active === false));
            this.commit('SET_ACTIVE_FILTER', null);
            this.commit('SET_UNACTIVE_FILTER', true);
            this.commit('SET_PAYMENT_FILTER', null);
          } else if (filter === 'asc') {
            this.commit('SET_SERVERS', res.data.sort((a, b) => a.name.localeCompare(b.name)));
          } else if (filter === 'desc') {
            this.commit('SET_SERVERS', res.data.sort((a, b) => b.name.localeCompare(a.name)));
          } else {
            this.commit('SET_UNACTIVE_FILTER', null);
            this.commit('SET_ACTIVE_FILTER', null);
            this.commit('SET_PAYMENT_FILTER', null);
            this.commit('SET_SERVERS', res.data);
          }
        }
      }
    },

    async getServersTransactions({ commit, state }) {
      const res = await paymentsRepository.getServersPay({ page: state.pageTransactions,
        per_page: 10 });
      if (res.data?.length) {
        this.commit('SET_TRANSACTIONS', res.data);
      }
    },
  },
};