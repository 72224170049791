export default [
  {
    path: '/analytics',
    component: () => import(/* webpackChunkName: "analytics" */ '../../views/Analytics/AnalyticsPage'),
    name: 'analytics',
    meta: {
      title: 'Аналитика',
    },
  },
];
