<template>
  <BaseButton class="hover-effect-2 color-gray h-fit mr-4">
    <BaseIcon :name="icon"/>
  </BaseButton>
</template>

<script>
import BaseButton from '@/components/base/BaseButton';
import BaseIcon from '@/components/base/BaseIcon';

export default {
  name: 'header-icon',
  components: {
    BaseButton,
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style scoped></style>
