import { http } from '@/plugins/axios';

const path = '/api/v1/auth';

export default {
  async getSelf() {
    const { data } = await http.get(`${path}/me`);
    return data;
  },

  async updateSelfProfile(payload) {
    const { data } = await http.patch(`${path}/update-profile`, payload);
    return data;
  },

  async login(payload) {
    await this.csrf();
    const { data } = await http.post(`${path}/login`, payload);
    return data;
  },

  async csrf() {
    return await http.get('/sanctum/csrf-cookie');
  },

  async getAbility(userId) {
    const { data } = await http.get(`${path}/${userId}`);
    return data;
  },
};
