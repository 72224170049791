export default [
  {
    path: '/streams',
    component: () => import(/* webpackChunkName: "streams" */ '../../views/Stream/StreamPage.vue'),
    name: 'streams',
    meta: {
      title: 'Стримы',
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "streams" */ '../../views/Stream/StreamList.vue'),
        name: 'streams.index',
      },
      {
        path: ':id',
        component: () => import(/* webpackChunkName: "streams" */ '../../views/Stream/_id.vue'),
        name: 'streams.id',
      },
    ],
  },
];
