import { http } from '@/plugins/axios';

let qs = require('qs');

const path = '/api/v1/accounts';

export default {
  /*
   * search - string
   * page - integer
   * limit - integer
   */
  async getAll(payload) {
    // const { data } = await http.get(`${path}?${func.getUrlDotParameters(payload)}`);
    const { data } = await http.get(`${path}?`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  /*
   * id - integer
   */
  async getActive(id) {
    const { data } = await http.get(`api/v1/accounts/${id}`, { withLoader: false });
    return data;
  },

  /*
   * id - integer
   */
  async deleteUser(id) {
    const { data } = await http.delete(`api/v1/accounts/${id}`);
    return data;
  },

  /*
   * id - integer
   */
  async enableUser(id) {
    const { data } = await http.post(`/api/v1/accounts/${id}/enable`);
    return data;
  },

  /*
   * id - integer
   */
  async disableUser(id) {
    const { data } = await http.post(`api/v1/accounts/${id}/disable`);
    return data;
  },

  /*
      email - string*
      login - string*
      first_name - string
      last_name - string
      avatar - file
    */
  async addFakeAccount(payload) {
    return await http.post('api/v1/accounts/fakes/create', payload, { withLoader: false });
  },

  async updateAccount(userId, payload) {
    const { data } = await http.patch(`api/v1/accounts/${userId}`, payload, { withLoader: false });
    return data;
  },

  async generateAccountPassword(userId) {
    const { data } = await http.post(`api/v1/accounts/${userId}/generate-password`);
    return data;
  },

  async transferAccount(userId, payload) {
    const { data } = await http.post(
      `api/v1/accounts/fakes/${userId}/send-link`,
      payload,
    );
    return data;
  },

  // Окончательное удаление пользователя
  async forceDeleteUser(id) {
    const { data } = await http.delete(`api/v1/accounts/${id}/force-delete`);
    return data;
  },

  // Восстановление пользователя из помеченных на удаление
  async restoreUser(id) {
    const { data } = await http.post(`/api/v1/accounts/${id}/restore`);
    return data;
  },

  async getAllForFilters(payload) {
    const { data } = await http.get(`${path}/for-filters`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },
};
