import { http } from '@/plugins/axios';
import qs from 'qs';

const path = '/api/v1/app';

export default {

  // Подписанные контракты монетизации
  async getContracts(payload) {
    const { data } = await http.get(`${path}/monetization/contracts`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async updateContract(id, payload) {
    const { data } = await http.patch(`${path}/monetization/${id}`, payload);
    return data;
  },

  // Налоговые данные
  async getTaxData(payload) {
    const { data } = await http.get(`${path}/tax-data`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getUserTaxData(id) {
    const { data } = await http.get(`${path}/tax-data/user/${id}`);
    return data;
  },

  async updateTaxData(id, payload) {
    const { data } = await http.patch(`${path}/tax-data/user/${id}`, payload);
    return data;
  },

  // Заявки на выплату средств
  async getTransaction(payload) {
    const { data } = await http.get(`${path}/transaction`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async updateTransaction(id, payload) {
    const { data } = await http.patch(`${path}/transaction/${id}`, payload);
    return data;
  },
};
