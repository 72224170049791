export default [
  {
    path: '/sms',
    component: () => import(/* webpackChunkName: "sms" */ '../../views/Sms/SmsPage.vue'),
    name: 'sms',
    meta: {
      title: 'СМС',
    },
  },
];
