import { uniqueId } from 'lodash';
class DrawerMenuItem {
    items;
    data;
    expanded;
    icon;
    id;
    selected;
    separator;
    text;
    parentId;
    visible;
    permission;
    notifications;
    constructor(data, parentId = null) {
        this.items = data.items;
        this.data = data.data;
        this.expanded = data.expanded;
        this.icon = data.icon;
        this.id = uniqueId('menu-item-');
        this.selected = data.selected;
        this.separator = data.separator;
        this.text = data.text;
        this.parentId = parentId;
        this.visible = !parentId;
        this.permission = data.permission;
    }
    get isParent() {
        return !!this?.items?.length;
    }
    setSelected(value) {
        this.selected = value;
        return this;
    }
    setExpanded(value) {
        this.expanded = value;
        return this;
    }
    setVisible(value) {
        this.visible = value;
        return this;
    }
    setNotifications(count) {
        this.notifications = count;
        return this;
    }
}
export default DrawerMenuItem;
