import { format, formatDistance } from 'date-fns';
import { formatBigNumber, formatDuration } from './utils/format.js';

class FormatTypes {
  // 7 сентября 2022 г.
  static DATE = 'date';

  // 7 сентября 2022 г. 00:00:00
  static DATETIME = 'datetime';

  // 3 часа назад
  static AGO = 'ago';

  // 10:00, 00:41
  static DURATION = 'duration';

  // 1K, 1.1M
  static BIG_NUMBER = 'bigNumber';

  // 1.0 тыс, 1.1 млн
  static BIG_NUMBER_SHORT = 'bigNumberShort';
}

export default async function (app) {
  const formatValue = (v, type = FormatTypes.DATE) => {
    if (!v && String(v) !== '0') return null;

    if (type === 'date') {
      return format(new Date(v), 'd MMMM yyyy') + app.i18n.t('general.year');
    } else if (type === 'datetime') {
      return format(new Date(v), `d MMMM yyyy ${app.i18n.t('general.year')} HH:mm:ss`);
    } else if (type === 'ago') {
      return formatDistance(new Date(v), new Date(), { addSuffix: true });
    } else if (type === 'duration') {
      return formatDuration(v);
    } else if (type === 'bigNumber') {
      return app.i18n.n(v, { notation: 'compact' });
    } else if (type === 'bigNumberShort') {
      return formatBigNumber(v);
    } else {
      return format(new Date(v), type);
    }
  };

  app.config.globalProperties.$format = formatValue;
}