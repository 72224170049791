export default [
  {
    path: '/monetization',
    component: () => import(/* webpackChunkName: "monetization" */ '@/views/Monetization/MonetizationLayout.vue'),
    meta: {
      title: 'Монетизация',
    },
    name: 'monetization',
    redirect: { name: 'monetization.contracts' },

    children: [
      {
        path: 'contracts',
        component: () => import(/* webpackChunkName: "monetization" */ '@/views/Monetization/MonetizationContracts.vue'),
        name: 'monetization.contracts',
      },
      {
        path: 'taxes',
        component: () => import(/* webpackChunkName: "monetization" */ '@/views/Monetization/MonetizationTaxes.vue'),
        name: 'monetization.taxes',
      },
      {
        path: 'withdrawal',
        component: () => import(/* webpackChunkName: "monetization" */ '@/views/Monetization/MonetizationWithdrawals.vue'),
        name: 'monetization.withdrawal',
      },
    ],
  },
];
