import accountsRepository from '@/repositories/accountsRepository';
import { useToast } from 'vue-toast-notification';

const $toast = useToast({
  position: 'top-right',
  duration: 2000,
});

export default {
  state: () => ({
    delete: false,
    edit: false,
    transfer: false,
    fake: false,
    create: false,
    navigator_create: false,
    active: null,
    cropper: false,
    server: false,
    payment: false,
    group: false,
    groupAdd: false,
  }),

  getters: {
    getDelete(state) {
      return state.delete;
    },
    getEdit(state) {
      return state.edit;
    },
    getTransfer(state) {
      return state.transfer;
    },
    getFake(state) {
      return state.fake;
    },
    getActive(state) {
      return state.active;
    },
    getCreate(state) {
      return state.create;
    },
    getNavigatorCreate(state) {
      return state.navigator_create;
    },
    getServer(state) {
      return state.server;
    },
    getPayment(state) {
      return state.payment;
    },
    getDeleteGroup(state) {
      return state.group;
    },
    getAddGroup(state) {
      return state.groupAdd;
    },
    getCropper(state) {
      return state.cropper;
    },
  },

  mutations: {
    SET_DELETE(state, item) {
      state.active = item;
      state.delete = true;
    },
    SET_EDIT(state, item) {
      state.active = item;
      state.edit = true;
    },
    SET_TRANSFER(state, item) {
      state.active = item;
      state.transfer = true;
    },
    SET_FAKE(state) {
      state.fake = true;
    },
    SET_CREATE(state) {
      state.create = true;
    },
    SET_NAVIGATOR_CREATE(state) {
      state.navigator_create = true;
    },
    SET_SERVER(state) {
      state.server = true;
    },
    SET_PAYMENT(state) {
      state.payment = true;
    },
    SET_GROUP(state) {
      state.group = true;
    },
    SET_GROUP_ADD(state) {
      state.groupAdd = true;
    },
    SET_CROPPER(state, cropperId) {
      state.cropper = cropperId;
    },
    UNSET_DELETE(state) {
      state.delete = false;
    },
    UNSET_GROUP(state) {
      state.group = false;
    },
    UNSET_GROUP_ADD(state) {
      state.groupAdd = false;
    },
    UNSET_EDIT(state) {
      state.edit = false;
    },
    UNSET_TRANSFER(state) {
      state.transfer = false;
    },
    UNSET_FAKE(state) {
      state.fake = false;
    },
    UNSET_CREATE(state) {
      state.create = false;
    },
    UNSET_NAVIGATOR_CREATE(state) {
      state.navigator_create = false;
    },
    UNSET_SERVER(state) {
      state.server = false;
    },
    UNSET_PAYMENT(state) {
      state.payment = false;
    },
    UNSET_CROPPER(state) {
      state.cropper = false;
    },
  },
  actions: {
    async deleteAccount(state) {
      const res = await accountsRepository.deleteUser(
        state.getters.getActive.id,
      );
      state.commit('UNSET_DELETE');
      if (res.success) {
        $toast.open({
          message: 'Аккаунт удален',
          type: 'success',
        });
      }
      return res.success;
    },
    async transferAccount(state, email) {
      const payload = { email };
      const res = await accountsRepository.transferAccount(
        state.getters.getActive.id,
        payload,
      );
      return res.success;
    },

    /* SET MODAL TRUE*/
    async setDelete({ commit }, options) {
      await commit('SET_DELETE', options);
    },
    async setEdit({ commit }, options) {
      await commit('SET_EDIT', options);
    },
    async setTransfer({ commit }, options) {
      await commit('SET_TRANSFER', options);
    },
    async setFake({ commit }) {
      await commit('SET_FAKE');
    },

    /* SET MODAL FALSE */
    async unsetDelete({ commit }) {
      await commit('UNSET_DELETE');
    },
    async unsetEdit({ commit }) {
      await commit('UNSET_EDIT');
    },
    async unsetTransfer({ commit }) {
      await commit('UNSET_TRANSFER');
    },
    async unsetFake({ commit }) {
      await commit('UNSET_FAKE');
    },
  },
};
