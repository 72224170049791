import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async getAll(payload) {
    const { data } = await http.get('api/v1/roles', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },
  async getAllPermissions(payload) {
    const { data } = await http.get('api/v1/permissions', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },
  async givePermission(id, payload) {
    await http.post(`api/v1/roles/${id}/give-permissions`, payload);
  },
  async revokePermission(id, payload) {
    await http.post(`api/v1/roles/${id}/revoke-permissions`, payload);
  },

  async add(payload) {
    const { data } = await http.post('/api/v1/news', payload);
    return data;
  },

  async getOneNews(id) {
    const { data } = await http.get(`/api/v1/news/${id}`);
    return data;
  },

  async edit(id, payload) {
    const { data } = await http.patch(`/api/v1/news/${id}`, payload);
    return data;
  },

  async delete(id) {
    const { data } = await http.delete(`/api/v1/news/${id}`);
    return data;
  },

  async publish(id) {
    const { data } = await http.post(`/api/v1/news/${id}/publish`);
    return data;
  },

  async unpublish(id) {
    const { data } = await http.post(`/api/v1/news/${id}/unpublish`);
    return data;
  },
};
