import { createStore } from 'vuex';

import enums from './modules/enums';
import auth from './modules/user';
import snackbar from './modules/snackbar';
import filters from './modules/filters';
import headerSearch from './modules/headerSearch';
import loader from './modules/loader';
import modals from './modules/modals';
import accounts from './modules/accounts';
import abilities from './modules/abilities';
import servers from './modules/servers';
// import complains from './modules/complains';
import activeElements from '@/store/modules/activeElements';
import verify from './modules/verify';
import files from './modules/files';
import news from './modules/news';
import categories from './modules/categories';
import analytics from './modules/analytics';
import componentsInfo from './modules/componentsInfo';
import domains from './modules/domains';
import pages from './modules/pages';
import viewMode from './modules/viewMode';

export default createStore({
  modules: {
    enums,
    auth,
    snackbar,
    filters,
    loader,
    modals,
    accounts,
    abilities,
    // complains,
    activeElements,
    verify,
    files,
    news,
    categories,
    analytics,
    componentsInfo,
    domains,
    pages,
    headerSearch,
    servers,
    viewMode,
  },
});
