export default [
  {
    path: '/strikes',
    name: 'strikes',
    component: () => import(/* webpackChunkName: "strikes" */ '@/views/Strikes/StrikesIndex'),
    meta: {
      title: 'Страйки',
    },
  },
];
