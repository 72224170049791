import { http } from '@/plugins/axios';
import qs from 'qs';

const path = '/api/v1/moderation';

export const moderationRepository = {
  async getAll(payload) {
    const { data } = await http.get(path, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getOne(id) {
    const { data } = await http.get(`${path}/${id}`, { withLoader: false });
    return data;
  },

  async updateModeration(id, payload) {
    const { data } = await http.post(`/api/v1/moderation-history/${id}`, payload);
    return data;
  },

  async getAllStrikes(payload) {
    const { data } = await http.get('/api/v1/strike', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async strike(payload) {
    const { data } = await http.post('/api/v1/strike', {
      // additional_action: 'block',
      // cause: '1313214da',
      // videos: payload,
      params: {
        // additional_action: 'block',
        filters: {
          video_id: payload,
        },
      },
    });
    return data;
  },

  async strikeById(id) {
    const { data } = await http.get(`/api/v1/strike/${id}`, { withLoader: false });
    return data;
  },

  async deleteStrike(id) {
    const { data } = await http.delete(`/api/v1/strike/${id}`, {
      params: {
        additional_action: 'unblock',
      } });
    
    return data;
  },
};
