import { useRoute, useRouter } from 'vue-router';
import {
  ACCOUNT_TYPE_QUERY,
  CONTENT_REPORT_STATUS_QUERY,
  CONVERT_STEP_QUERY,
  FILE_TYPE_QUERY,
  FILTER_VERIFY_STATUS_QUERY,
  IS_UPLOADED_QUERY,
  MODERATION_STATUS_QUERY,
  NEWS_STATUS_QUERY,
  NEWS_TYPE_QUERY,
  NOTIFICATION_STATUS_QUERY,
  PAGE_QUERY,
  PER_PAGE_QUERY,
  SORT_BY_QUERY,
  SORT_ORDER_QUERY,
  STREAM_STATUS_QUERY,
  TAX_DATA_STATUS_QUERY,
  TRANSACTION_STATUS_QUERY,
  VERIFY_STATUS_QUERY,
  VIEW_MODE_QUERY,
} from '@/assets/js/const';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { isEmpty } from 'lodash';
import NotificationStatus from '@/assets/js/notifications/notification-status';
import { NewsItemStatus } from '@/assets/js/news/NewsItemStatus';

export const useQuery = () => {
  const route = useRoute();
  const router = useRouter();
  const store = useStore();

  const VideoUploadStatus = computed(() => store.getters.enumByName('VideoUploadStatus'));
  const VideoConvertStatus = computed(() => store.getters.enumByName('VideoConvertStatus'));
  const ModerationStatus = computed(() => store.getters.enumByName('ModerationStatus'));
  const VerificationStatus = computed(() => store.getters.enumByName('VerificationStatus'));
  const ContentReportStatus = computed(() => store.getters.enumByName('ContentReportStatus'));
  const StreamStatus = computed(() => store.getters.enumByName('StreamStatus'));
  const AccountType = computed(() => store.getters.enumByName('AccountType'));
  const TaxDataStatus = computed(() => store.getters.enumByName('TaxDataStatus'));
  const TransactionStatus = computed(() => store.getters.enumByName('TransactionStatus'));
  const NewsType = computed(() => store.getters.enumByName('NewsType'));
  const VideoType = computed(() => store.getters.enumByName('VideoType'));

  const setQuery = (newQuery) => {
    const query = { ...route.query };
    for (const key in newQuery) {
      if (newQuery[key] !== undefined) {
        query[key] = newQuery[key];
      } else {
        delete query[key];
      }
    }
    router.replace({ query });
  };

  const resetQuery = () => {
    const query = { ...route.query };
    for (const key in query) {
      delete query[key];
    }
    // Preserve view mode if set
    if (route.query[VIEW_MODE_QUERY]) {
      query[VIEW_MODE_QUERY] = route.query[VIEW_MODE_QUERY];
    }
    router.replace({ query });
  };

  const getQueryFilterValue = (filterKey) => {
    const query = route.query;
    if (query[filterKey]) {
      switch (filterKey) {
      case VERIFY_STATUS_QUERY: {
        return getEnumElementByValue(VerificationStatus.value, [query[filterKey]]);
      }
      case IS_UPLOADED_QUERY: {
        return getEnumElementByValue(VideoUploadStatus.value, [query[filterKey]]);
      }
      case CONVERT_STEP_QUERY: {
        return getEnumElementByValue(VideoConvertStatus.value, [query[filterKey]]);
      }
      case MODERATION_STATUS_QUERY: {
        return getEnumElementByValue(ModerationStatus.value, [query[filterKey]]);
      }
      case ACCOUNT_TYPE_QUERY: {
        return getEnumElementByValue(AccountType.value, [query[filterKey]]);
      }
      case FILTER_VERIFY_STATUS_QUERY: {
        return getEnumElementByValue(VerificationStatus.value, [query[filterKey]]);
      }
      case CONTENT_REPORT_STATUS_QUERY: {
        return getEnumElementByValue(ContentReportStatus.value, [query[filterKey]]);
      }
      case STREAM_STATUS_QUERY: {
        return getEnumElementByValue(StreamStatus.value, query[filterKey]);
      }
      case TAX_DATA_STATUS_QUERY: {
        return getEnumElementByValue(TaxDataStatus.value, query[filterKey]);
      }
      case NEWS_TYPE_QUERY: {
        return getEnumElementByValue(NewsType.value, query[filterKey]);
      }
      case TRANSACTION_STATUS_QUERY: {
        return getEnumElementByValue(TransactionStatus.value, query[filterKey]);
      }
      case NEWS_STATUS_QUERY: {
        return getEnumElementByValue(NewsItemStatus, query[filterKey]);
      }
      case NOTIFICATION_STATUS_QUERY: {
        return NotificationStatus.find(element => element.value === query[filterKey]);
      }
      case FILE_TYPE_QUERY: {
        return getEnumElementByValue(VideoType.value, query[filterKey]);
      }
      }
    }
  };

  const onSortChange = (e) => {
    let sortBy = undefined;
    let sortOrder = undefined;
    if (e.sort.length > 0) {
      sortBy = e.sort[0].field;
      sortOrder = e.sort[0].dir;
    }
    setQuery({
      [SORT_BY_QUERY]: sortBy,
      [SORT_ORDER_QUERY]: sortOrder,
    });
  };

  const sort = computed(() => {
    const query = { ...route.query };
    if (query[SORT_BY_QUERY] && query[SORT_ORDER_QUERY]) {
      return [{
        field: query[SORT_BY_QUERY],
        dir: query[SORT_ORDER_QUERY],
      }];
    }
    return null;
  });

  const onFilterChange = (e) => {
    const newQuery = {};
    e.filter.filters.forEach(filter => {
      newQuery[filter.field] = filter.value;
    });
    setQuery(newQuery);
  };

  const onPageChange = (e, emit) => {
    const page = e.page.skip / e.page.take + 1;
    emit('update:page', page);
    emit('update:per-page', e.page.take);
    setQuery({
      [PAGE_QUERY]: page,
      [PER_PAGE_QUERY]: e.page.take,
    });
  };

  const isEmptyQuery = computed(() => {
    return isEmpty(route.query);
  });

  const mode = computed(() => route.query?.[VIEW_MODE_QUERY] ?? 'list');

  function getEnumElementByValue(enumItem, value) {
    return enumItem.find(element => element.value === +value);
  }

  return {
    setQuery,
    getQueryFilterValue,
    resetQuery,
    onPageChange,
    onFilterChange,
    onSortChange,
    isEmptyQuery,
    sort,
    mode,
  };
};