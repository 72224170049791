export default [
  {
    path: '/verify',
    component: () => import(/* webpackChunkName: "verify" */ '../../views/Verify/VerifyPage'),
    name: 'verify',
    meta: {
      title: 'Запросы верификации',
    },
  },
];
