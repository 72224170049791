export default [
  {
    path: '/playlists',
    name: 'playlists',
    component: () => import(/* webpackChunkName: "playlists" */ '../../views/Playlists/Playlists.vue'),
    meta: {
      title: 'Плейлисты',
    },
  },
];
