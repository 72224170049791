export default {
  state: () => ({
    loader: '',
  }),

  getters: {
    getLoader(state) {
      return state.loader;
    },
  },

  mutations: {
    SET_LOADER(state, value) {
      state.loader = value;
    },
  },

  actions: {
    setLoader(state, value) {
      state.commit('SET_LOADER', value);
    },
  },
};
