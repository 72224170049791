import domainsRepository from '@/repositories/domainsRepository';
import router from '@/router';
import config from '@/config';

export default {
  state: () => ({
    domains: null,
  }),

  getters: {
    getDomains(state) {
      return state.domains;
    },
    getSelectedAll(state, getters) {
      return getters.getSelectedCount === state.domains.length && state.domains.length !== 0;
    },
    getSelected(state) {
      return state.domains.filter(item => item.checked);
    },
    getSelectedCount(state) {
      return state.domains ? state.domains.filter(item => item.checked).length : 0;
    },
    getItemsLength(state) {
      return state.domains ? state.domains.length : 0;
    },
  },

  mutations: {
    SET_DOMAINS(state, res) {
      if (res) {
        state.domains = res.data;
        state.domains.forEach(item => item.checked = false);
      } else {
        state.domains = null;
      }
    },
    SELECT_ALL(state) {
      state.domains.forEach(item => item.checked = true);
    },
    UNSELECT_ALL(state) {
      state.domains.forEach(item => item.checked = false);
    },
  },

  actions: {
    async setDomains(state) {
      const query = await domainsRepository.getAll(config.domains);
      if (query?.meta?.total) {
        if (query.data.length === 0) {
          await router.push({ name: 'blocked-domains' });
        } else {
          state.commit('SET_DOMAINS', query);
        }
      } else {
        state.commit('SET_DOMAINS', null);
      }
      state.commit('SET_PAGES', query.meta);
    },
  },
};