import { http } from '@/plugins/axios';
import qs from 'qs';

const path = '/api/v1/videos';

export default {
  async getAll(payload) {
    const { data } = await http.get(`${path}`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getAllWithComments(payload) {
    const { data } = await http.get(`${path}/with-comments`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getOne(id) {
    const { data } = await http.get(`${path}/${id}`,
      { withLoader: false },
    );
    return data;
  },

  // payload - name, description, category_id, tags
  async updateOne(id, payload) {
    const { data } = await http.put(`${path}/${id}`, payload);
    return data;
  },

  async block(id, payload) {
    let data = [];
    data = await http.post(`${path}/${id}/block`, payload, { withLoader: false });
    return { data };
  },

  async blockById(id, payload) {
    const { data } = await http.post(`${path}/${id}/block`, payload, { withLoader: false });
    return data;
  },

  async unblock(id) {
    const { data } = await http.post(`${path}/${id}/unblock`, {}, { withLoader: false });
    return data;
  },

  async remove(id) {
    const { data } = await http.delete(`${path}/${id}`, { withLoader: false });
    return data;
  },

  async permanentRemove(payload) {
    const { data } = await http.delete(`${path}/delete/force`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async removeForce(id, payload) {
    let data = [];
    typeof payload.value === 'object'
      ? data = await http.delete(`${path}/${id}/forceDelete`, { params: { blocked_reason: payload.value.name } })
      : data = await http.delete(`${path}/${id}/forceDelete`, { params: { blocked_reason: payload.value } });
    return data;
  },

  async removeForceById(id, payload) {
    const { data } = await http.delete(`${path}/${id}/forceDelete`,
      {
        params: payload,
        withLoader: false,
      });
    return data;
  },

  async getConverterData(params) {
    const { data } = await http.get('api/v1/converter/answers', {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async getConverterTasks(params) {
    const { data } = await http.get('api/v1/converter/tasks', {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async resendConverterTask(id) {
    const { data } = await http.post('api/v1/converter/task/resend', { task_id: id }, { withLoader: false });
    return data;
  },

  async resendAllTasks(videoId) {
    const { data } = await http.post('api/v1/converter/task/all/resend', { video_id: videoId }, { withLoader: false });
    return data;
  },

  async restore(id) {
    const { data } = await http.post(`${path}/${id}/restore`);
    return data;
  },

  async strike({
    additional_action,
    cause,
    videos,
    cause_for_user,
  }) {
    const { data } = await http.post('/api/v1/strike', {
      additional_action: additional_action,
      cause: cause,
      videos: videos,
      cause_for_user: cause_for_user,
    }, { withLoader: false });
    return data;
  },

  async deleteStrike(id, action) {
    const { data } = await http.delete(`/api/v1/strike/${id}`, {
      params: {
        additional_action: action,
      },
      withLoader: false,
    });

    return data;
  },
};
