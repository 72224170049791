import auth from './auth';
import access from './access';
import accounts from './accounts';
import settings from './settings';
import profile from './profile';
import language from './language';
import verify from './verify';
import complains from './complains';
import downloads from './downloads';
import analytics from './analytics';
import categories from '@/router/routes/categories';
import news from '@/router/routes/news';
import blockedDomains from '@/router/routes/blockedDomains';
import moderatorAnalytics from '@/router/routes/moderatorAnalytics';
import monetization from '@/router/routes/monetization';
import videoData from '@/router/routes/videoData';
import servers from '@/router/routes/servers';
import streams from '@/router/routes/streams';
import strikes from '@/router/routes/strikes';
import notifications from '@/router/routes/notifications';
import sms from '@/router/routes/sms';
import channels from '@/router/routes/channels';
import playlists from '@/router/routes/playlists';
import donate from '@/router/routes/donate';

export default [
  {
    path: '/',
    component: () => import('../../views/Index'),
    name: 'admin',
    meta: {
      title: 'Главная',
    },
  },
  ...auth,
  ...access,
  ...settings,
  ...analytics,
  ...accounts,
  ...settings,
  ...profile,
  ...language,
  ...verify,
  ...complains,
  ...downloads,
  ...analytics,
  ...categories,
  ...news,
  ...blockedDomains,
  ...moderatorAnalytics,
  ...monetization,
  ...videoData,
  ...servers,
  ...streams,
  ...strikes,
  ...channels,
  ...playlists,
  ...notifications,
  ...sms,
  ...donate,
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404'),
  },
];
