export default [
  {
    path: '/moderator-analytics',
    component: () => import(/* webpackChunkName: "moderator-analytics" */ '../../views/ModeratorAnalytics/ModeratorAnalyticsPage'),
    name: 'moderator-analytics',
    meta: {
      title: 'Аналитика модераторов',
    },
  },
];
