import verifyRepository from '@/repositories/verifyRepository';
import config from '@/config';

export default {
  state: () => ({
    verifyAccounts: null,
    verifyModal: null,
    activeAccount: null,
  }),

  getters: {
    getVerifyAccounts(state) {
      return state.allAccounts;
    },
    getVerifyModal(state) {
      return state.verifyModal;
    },
    getVerifyActiveAccount(state) {
      return state.activeAccount;
    },
    hasVerifyAccounts(state) {
      return !!state?.verifyAccounts?.length;
    },
  },

  mutations: {
    SET_VERIFY_ACCOUNTS(state, data) {
      // res.accounts.forEach((el) => {
      //   el.type = Math.floor(Math.random() * 2);
      //   el.info = [
      //     {
      //       key: 'Дата получения',
      //       value: '24.04.2022',
      //       copy: true,
      //     },
      //     {
      //       key: 'Тип аккаунта',
      //       value: el.type === 1 ? 'Юридическое лицо' : 'Физическое лицо',
      //     },
      //     {
      //       key: 'Документы',
      //       value: 'Загружены ',
      //       svg: '<svg fill="none" height="9" viewBox="0 0 12 9" width="12" xmlns="http://www.w3.org/2000/svg"><path d="M4.36665 9L0.56665 5.2L1.51665 4.25L4.36665 7.1L10.4833 0.983337L11.4333 1.93334L4.36665 9Z" fill="#44A766"/></svg>',
      //       class: 'success flex items-center gap-1',
      //     },
      //     {
      //       key: 'Статус',
      //       icon: `status-${Math.floor(Math.random() * 4)}`,
      //     },
      //   ];
      // });
      state.allAccounts = data;
    },
    SET_VERIFY_MODAL(state, acc) {
      state.activeAccount = acc;
      state.verifyModal = acc.type;
    },
    UNSET_VERIFY_MODAL(state) {
      state.activeAccount = null;
      state.verifyModal = null;
    },
  },

  actions: {
    // FIXME: NEED API DATA
    async setVerifyAccounts(state) {
      const query = await verifyRepository.getAll(config.verify);
      if (query?.meta?.total) {
        if (query.data.length === 0) {
          state.commit('SET_VERIFY_ACCOUNTS', null);
        } else {
          state.commit('SET_VERIFY_ACCOUNTS', query.data);
        }
      } else {
        state.commit('SET_VERIFY_ACCOUNTS', null);
      }
    },
  },
};
