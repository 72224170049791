<template>
  <router-link
    :to="{ name: to }"
    class="profile__info-menu__link py-2 px-4 flex items-center hover-effect-2 whitespace-nowrap text-sm color-text"
  >
    <BaseIcon
      :name="icon"
      class="btn-icon mr-2"
    />
    {{ title }}
  </router-link>
</template>

<script>
import BaseIcon from '@/components/base/BaseIcon';

export default {
  name: 'profile-menu-item',

  components: {
    BaseIcon,
  },

  props: {
    to: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    icon: {
      type: String,
      default: '',
      required: true,
    },
  },
};
</script>

<style scoped></style>
