import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async getSearchStatus() {
    const { data } = await http.get('api/v1/app/meilisearch/status ');
    return data;
  },

  async setSearchState() {
    const { data } = await http.put('api/v1/app/meilisearch/status/activate');
    return data;
  },

  async startReIndex() {
    const { data } = await http.post('api/v1/app/meilisearch/index');
    return data;
  },

  async getSettings(payload) {
    const { data } = await http.get('api/v1/app/meilisearch/settings', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async saveSettings(payload) {
    const { data } = await http.put('api/v1/app/meilisearch/settings', payload);
    return data;
  },

  async syncSettings() {
    const { data } = await http.post('api/v1/app/meilisearch/settings/sync');
    return data;
  },

  async exampleSearch(payload) {
    const { data } = await http.get('api/v1/app/meilisearch/search', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async multiSearch(payload) {
    const { data } = await http.get('api/v1/app/meilisearch/multi-search', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async getAppHome(payload) {
    const { data } = await http.get('api/v1/app/home', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async updateAppHome(payload) {
    const { data } = await http.patch('api/v1/app/home', payload);
    return data;
  },

  // Video Player aliases

  async getAliases(payload) {
    const { data } = await http.get('api/v1/app/video/player/alias', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async addAlias(payload) {
    const { data } = await http.post('api/v1/app/video/player/alias', payload );
    return data;
  },

  async updateAlias(id, payload) {
    const { data } = await http.patch(`api/v1/app/video/player/alias/${id}`, payload);
    return data;
  },

  async removeAlias(id) {
    const { data } = await http.delete(`api/v1/app/video/player/alias/${id}`);
    return data;
  },

  async applyAlias(payload) {
    const { data } = await http.post('api/v1/app/video/player/alias/apply', payload);
    return data;
  },

  async updateVideoLabel(videoId, payload) {
    const { data } = await http.patch(`api/v1/app/video/${videoId}/label`, payload);
    return data;
  },

  async getConverterSettings() {
    const { data } = await http.get('api/v1/converter/settings');
    return data;
  },

  async saveConverterSettings(payload) {
    const { data } = await http.patch('api/v1/converter/settings', payload);
    return data;
  },

  async getAppSettings(payload) {
    const { data } = await http.get('api/v1/settings', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async patchAppSettings(payload) {
    const { data } = await http.patch('api/v1/app/settings', payload);
    return data;
  },

  async getSetting(settingId) {
    const { data } = await http.get(`api/v1/settings/${settingId}`);
    return data;
  },

  async updateSetting(settingId, payload) {
    const { data } = await http.put(`api/v1/settings/${settingId}`, payload);
    return data;
  },
};