export default [
  {
    name: 'complains',
    path: '/complains',
    component: () => import(/* webpackChunkName: "complaints" */ '@/views/Complains/ComplainLayout.vue'),
    meta: {
      title: 'Жалобы',
    },
    redirect: { name: 'complains.video' },
    children: [
      {
        path: 'video',
        component: () => import(/* webpackChunkName: "complaints" */ '@/views/Complains/ComplaintsVideoReports.vue'),
        name: 'complains.video',
        children: [
          {
            path: ':id/:complaint_id?',
            component: () => import(/* webpackChunkName: "complaints" */ '@/views/Complains/VideoReportComplaint_id.vue'),
            name: 'video.complaint_id',
          },
        ],
      },
      {
        path: 'copyright',
        component: () => import(/* webpackChunkName: "complaints" */ '@/views/Complains/ComplaintsCopyrightReports.vue'),
        name: 'complains.copyright',
        children: [
          {
            path: ':id',
            component: () => import(/* webpackChunkName: "complaints" */ '@/views/Complains/CopyrightReportComplaint_id.vue'),
            name: 'copyright.complaint_id',
          },
        ],
      },
    ],
  },
];
