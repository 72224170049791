export default {
  state: () => ({
    abilities: null,
  }),

  getters: {
    getAbilities(state) {
      return state.abilities;
    },
  },

  mutations: {
    SET_ABILITIES(state, abilities) {
      state.abilities = abilities;
    },
  },

  actions: {
    setAbilities(state, abilities) {
      state.commit('SET_ABILITIES', abilities);
    },
  },
};
