import channelsRepository from '@/repositories/channelsRepository';
import router from '@/router';
import { merge } from 'lodash';
import { dateFormat } from '@/plugins/functions';

export default {
  state: () => ({
    analytics: null,
    channels: null,
  }),

  getters: {
    getAnalytics(state) {
      return state.analytics;
    },
    getChannels(state) {
      return state.channels;
    },
  },

  mutations: {
    SET_ANALYTICS(state, res) {
      res.items.forEach((el) => {
        el.image = {
          src: el.avatar,
          height: 64,
          width: 96,
        };
        el.info = [
          {
            title: 'Канал',
            image: {
              src: require('@/assets/images/attachment-1.png'),
              height: 30,
              width: 30,
            },
            text: 'Women\'s Sherpa Full',
            link: '#',
            copy: false,
          },
          {
            title: 'CTR',
            text: '9%',
          },
          {
            title: 'Досмотры, мин',
            text: '0,25',
          },
          {
            title: 'Тип рекламы',
            text: 'Пре-ролл',
          },
          {
            title: 'География',
            data: [
              {
                name: 'Россия',
                value: 880,
              },
              {
                name: 'Беларусь',
                value: 654,
              },
              {
                name: 'Казахстан',
                value: 335,
              },
              {
                name: 'Эстония',
                value: 245,
              },
              {
                name: 'Украина',
                value: 163,
              },
              {
                name: 'Финляндия',
                value: 160,
              },
              {
                name: 'Латвия',
                value: 149,
              },
            ],
          },
          {
            title: 'Прибыль, руб.',
            text: '1 365',
          },
        ];
      });

      state.analytics = res;
    },
    SET_CHANNELS(state, res) {
      state.channels = res.items.map((el, index) => {
        return merge(el, {
          image: {
            src: el.getUserImage,
            height: 64,
            width: 96,
          },
          ctr: 1 + index,
          profit: 1 + index * 2,
          watches: 1 + index * 3,
          info: [
            {
              key: 'ctr',
              title: 'ctr',
              text: 1 + index + '%',
            },
            {
              key: 'profit',
              title: 'Профит',
              text: 1 + index * 2 + '$',
            },
            {
              key: 'watches',
              title: 'Досмотры',
              text: 1 + index * 3,
            },
            {
              title: 'id',
              text: el.id,
            },
            {
              title: 'email',
              text: el.email,
            },
            {
              key: 'subscribers',
              title: 'Кол-во подписчиков',
              text: el.subscribers_count,
            },
            {
              title: 'Дата создания',
              text: dateFormat.getFormatDate(el.created_at),
            },
            {
              title: 'Дата изменения',
              text: dateFormat.getFormatDate(el.updated_at),
            },
          ],
        });
      });
      state.total = res.total;
    },
  },

  actions: {
    // FIXME: NEED API DATA
    async setAnalytics(state) {
      const query = await channelsRepository.getAll(
        state.getters.analyticsFilter);
      if (query.success) {
        if (query.data.length === 0 && state.getters.downloadsFilter.page) {
          state.commit('SET_LOADER', 1);
          await router.push({ name: 'downloads' });
        } else {
          const res = {
            items: query.data,
            total: query.total,
          };
          state.commit('SET_CHANNELS', res);
        }
      }
    },

    async sortChannels({ state }, { type, key }) {
      if (type === 'desc' || type === 'asc') {
        state.channels.sort((a, b) => {
          if (key === 'name') {
            return type === 'asc' ?
              a[key].localeCompare(b[key]) :
              b[key].localeCompare(a[key]);
          } else {
            return type === 'asc' ?
              a[key] - b[key] :
              b[key] - a[key];
          }
        });
      }
    },
  },
};
