import { useSocketChannel } from '@/compositions/socket/useSocket';
import { onMounted, onUnmounted, ref } from 'vue';
import { useSocketListen } from '@/compositions/socket/useSocketListen';

export const useSocketRoom = (room, eventHandlers) => {
  const unsubscribeUserRoom = ref(null);

  Object.entries(eventHandlers).forEach(
    ([ev, handler]) => useSocketListen(ev, handler),
  );

  onMounted(() => {
    const [subscribe, unsubscribe] = useSocketChannel(room);
    unsubscribeUserRoom.value = unsubscribe;
    subscribe();
  });

  onUnmounted(() => {
    if (unsubscribeUserRoom.value !== null) {
      unsubscribeUserRoom.value();
    }
  });

  return {
    useSocketRoom,
  };
};
