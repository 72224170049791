export default {
  state: () => ({
    viewMode: 'list', // default is table view
  }),

  getters: {
    getViewModeValue(state) {
      return state.viewMode;
    },
  },

  mutations: {
    SET_VIEW_MODE(state, value) {
      state.viewMode = value;
    },
  },
};
