import { http } from '@/plugins/axios';
import qs from 'qs';

const path = '/api/v1/strike';
const accountsPath = 'api/v1/accounts/blocked_for_strike';

export default {
  async getAllAccounts(payload) {
    const { data } = await http.get(accountsPath, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getOne(id) {
    const { data } = await http.get(`${path}/${id}`, { withLoader: false });
    return data;
  },

  async addStrike(payload) {
    return await http.post(path, payload, {
      withLoader: false,
    });
  },

  async deleteStrike(id) {
    const { data } = await http.delete(`${path}/${id}`, {
      params: {
        additional_action: 'unblock',
        withLoader: false,
      },
    });
    return data;
  },

  // удалить все страйки пользователя
  async unblockAccount(id) {
    const { data } = await http.delete(`${path}/user/${id}`, {
      withLoader: false,
    });
    return data;
  },
};

