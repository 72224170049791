export default [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "auth" */ '../../views/Auth/AuthLogin'),
    name: 'login',
    meta: {
      title: 'Логин',
    },
  },
  {
    path: '/logout',
    component: () => import(/* webpackChunkName: "auth" */ '../../views/Auth/AuthLogout'),
    name: 'logout',
    meta: {
      title: 'Logout',
    },
  },
];
