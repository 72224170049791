import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async getAll(payload) {
    const { data } = await http.get('api/v1/user-notifications', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },
};
