export default [
  {
    path: '/downloads',
    component: () => import(/* webpackChunkName: "downloads" */ '../../views/Downloads/DownloadsPage'),
    name: 'downloads',
    meta: {
      title: 'Загрузки',
    },
  },
];
