import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async getAll(payload) {
    const { data } = await http.get('/api/v1/users', { params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async findList(payload) {
    const response = await fetch('/api/v1/users?' + qs.stringify(payload));

    return await response.json();
  },

  async find(id) {
    const response = await fetch('/api/v1/users/' + id, { withLoader: false });
    return await response.json();
  },

  async add(payload) {
    const { data } = await http.post('api/v1/users', payload, { withLoader: false });
    return data;
  },

  async update(userId, payload) {
    const { data } = await http.patch(`/api/v1/users/${userId}`, payload);
    return data;
  },

  async destroy(payload) {
    const { data } = await http.delete('/api/v1/users/destroy', {
      data: payload,
    });
    return data;
  },
};
