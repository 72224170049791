export default [
  {
    path: '/donate',
    component: () => import(/* webpackChunkName: "donate" */ '../../views/Donate/Donate'),
    name: 'donate',
    meta: {
      title: 'Донаты',
    },
  },
];
