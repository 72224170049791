<template>
  <button
    :class="classes"
    :disabled="disabled"
    :type="type"
    v-bind="$attrs"
  >
    <slot>
      <div
        class="flex items-center"
        :class="{ 'gap-2': icon && text }"
      >
        <div v-if="!textAppend">
          {{ text }}
        </div>
        <BaseIcon
          v-if="icon"
          :name="icon"
          class="btn-icon"
          :size="size"
        />
        <div v-if="textAppend">
          {{ text }}
        </div>
      </div>
    </slot>
  </button>
</template>

<script>
import BaseIcon from './BaseIcon';

export default {
  name: 'base-button',

  components: { BaseIcon },

  props: {
    color: {
      type: String,
      default: '',
      // Validator: (v) => ["primary", "secondary", "danger"].includes(v),
    },
    icon: {
      type: String,
      default: null,
    },

    outline: Boolean,
    text: {
      type: String,
      default: '',
    },

    textAppend: {
      type: Boolean,
      default: false,
    },

    small: Boolean,
    size: {
      type: [Number, String],
      default: 24,
    },

    type: {
      type: String,
      default: 'button',
    },
    disabled: Boolean,
    bgWhite: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        btn: true,

        'btn--primary': this.color === 'primary',
        'btn--secondary': this.color === 'secondary',
        'btn--danger': this.color === 'danger',

        'btn--outline': this.outline,
        'btn--disabled': this.disabled,

        'btn--sm': this.small,
        'btn--icon': this.icon,
        'btn--bg-white': this.bgWhite,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  font: {
    size: 1rem;
    weight: 500;
  }
  height: 40px;
  justify-content: center;
  padding: 0;
  text-decoration: none;
  transition: color var(--transition-timing-function) var(--transition-duration),
  border-color var(--transition-timing-function) var(--transition-duration),
  background var(--transition-timing-function) var(--transition-duration);

  &.btn--sm {
    font-size: 0.85em;
    font-weight: 500;
    padding: 0 !important;
    text-transform: none !important;

    &:hover {
      background-color: transparent !important;
    }
  }

  &.btn--icon {
    padding: 11px !important;
    // height: 100% !important;
  }

  &.btn--primary:not(&.btn--outline):not(&.btn--text) {
    background: var(--color-one-accent);
    border: var(--border-width) solid var(--color-one-accent);
    border-radius: var(--border-radius-input);
    color: #fff;
    padding: 12px 32px;

    &:hover {
      background: var(--color-one-accent-hover);
      border-color: var(--color-one-accent-hover);
    }
  }

  &.btn--primary.btn--outline {
    border: var(--border-width) solid currentColor;
    border-radius: var(--border-radius-input);
    color: var(--color-one-accent);
    padding: 7px 32px;

    &:hover {
      background: var(--color-one-accent-hover-opacity);
    }
  }

  &.btn--primary.btn--text {
    border-radius: var(--border-radius);
    color: var(--color-one-accent);
    padding: 10px 32px;
    @media (max-width: 640px) {
      padding: 12px 32px;
    }

    &:hover {
      background: var(--color-one-accent-hover-opacity);
      color: var(--color-one-accent-hover);

      svg {
        fill: var(--color-one-accent-hover);
      }
    }
  }

  &.btn--secondary:not(&.btn--outline):not(&.btn--text) {
    border: var(--border-width) solid var(--text-sub-color);
    border-radius: var(--border-radius-input);
    color: #fff;
    font-weight: 500;
    padding: 12px 32px;
  }

  &.btn--secondary.btn--outline {
    border: var(--border-width) solid currentColor;
    border-radius: var(--border-radius-input);
    color: var(--text-color);
    padding: 7px 32px;

    &:hover {
      background: var(--text-color-btn-hover);
    }
  }

  &.btn--secondary.btn--text {
    border-radius: var(--border-radius);
    color: var(--color-black);
    padding: 10px 32px;
    @media (max-width: 640px) {
      padding: 12px 32px;
    }

    &:hover {
      background: var(--color-one-accent-hover-opacity);
      color: var(--color-one-accent-hover);

      svg {
        fill: var(--color-one-accent-hover);
      }
    }
  }

  &.btn--danger:not(&.btn--outline):not(&.btn--text) {
    background: var(--color-two-accent);
    border: var(--border-width) solid var(--color-two-accent);
    border-radius: var(--border-radius-input);
    color: #fff;
    padding: 12px 32px;

    &:hover {
      background: var(--color-two-accent-hover);
      border-color: var(--color-two-accent-hover);
    }
  }

  &.btn--danger.btn--outline {
    background: transparent;
    border: var(--border-width) solid var(--color-two-accent);
    border-radius: var(--border-radius-input);
    color: var(--color-two-accent);
    padding: 12px 32px;

    &:hover {
      background: var(--color-two-accent-hover-opacity);
    }
  }

  &.btn--toggle_menu {
    &:hover {
      color: var(--color-two-accent) !important;
    }
  }

  &.btn--login {
    @media (max-width: 680px) {
      padding: {
        bottom: 12px;
        top: 12px;
      }
    }

    .btn-icon {
      margin-right: 8px;
      @media (max-width: 680px) {
        display: none;
      }
    }
  }

  &.btn--alert {
    position: relative;

    &.active::after {
      background: var(--color-two-accent);
      border-radius: 50%;
      content: "";
      display: block;
      height: 10px;
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
    }
  }

  &.btn--control-button {
    background: var(--color-tree-accent);
    border-radius: var(--border-radius);
    color: var(--color-black);
    padding: 10px 32px;

    &:hover {
      background: var(--color-tree-accent-hover);
    }
  }

  &.btn--disabled {
    background: var(--background-color-btn-disabled) !important;
    border-color: var(--background-color-btn-disabled) !important;
    color: var(--text-color-btn-disabled) !important;
    cursor: no-drop !important;
  }

  &.btn--bg-white {
    background: #FFFFFF;
  }
}
</style>
