export default [
  {
    path: '/notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '../../views/Notifications/Notifications'),
    name: 'notifications',
    meta: {
      title: 'Уведомления',
    },
  },
];
