<template>
  <div
    :class="classes"
    class="rounded px-1 py-0.5 text-xs w-max inline-flex items-center justify-center"
  >
    {{ message }}
  </div>
</template>

<script setup>
import { computed } from 'vue';

// eslint-disable-next-line no-undef
const props = defineProps({
  color: {
    type: String,
    default: 'secondary',
  },
  message: {
    type: String,
    default: 'неправильный статус',
  },
  outline: {
    type: Boolean,
    default: false,
  },
});

const classes = computed(() => {
  return { 'text-white': !props.outline,
           [`k-color-${props.color}`]: props.outline,
           [`k-border-${props.color}`]: props.outline,
           'border': props.outline,
           [`k-bg-${props.color}`]: !props.outline };
});

</script>