import { http } from '@/plugins/axios';
import qs from 'qs';

const path = '/api/v1/comments';

export default {
  async getAll(payload) {
    const { data } = await http.get('/api/v1/comments',
      {
        params: payload,
        withLoader: false,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        } });
    return data;
  },

  async getOne(id) {
    const { data } = await http.get(`${path}/${id}`);
    return data;
  },

  async removeOneById(id) {
    const { data } = await http.delete(`${path}/${id}`, { withLoader: false });
    return data;
  },

  async removeAllByAccountId(id) {
    const { data } = await http.delete(`${path}/account/${id}`, { withLoader: false });
    return data;
  },
};