import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
import store from '@/store';
// import config from '@/config';
// import Vue from 'vue';

const router = createRouter({
  routes,
  history: createWebHistory(),
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({
    left: 0,
    top: 0,
  }),
});

router.beforeEach(async (to, from, next) => {
  const isLoggedIn = localStorage.getItem('isLoggedIn');
  console.info('isLoggedIn: ', isLoggedIn);

  if (!store.getters.user) {
    await store.dispatch('fetchUser');
  }

  const user = store.getters.user;

  if ('title' in to.meta) {
    document.title = to.meta.title;
    let description = document.querySelector('head meta[name="description"]');
    if (description && to.meta['description'])
      description.setAttribute('content', to.meta.description);
  }

  console.info(to, to.name);

  if (to.name === undefined) {
    next({ name: '404' });
  }

  if (to.name === 'admin' && isLoggedIn) {
    if (user.roles.some(role => role.name === 'moderator')) {
      next({ name: 'downloads' });
    }
  }

  if (!isLoggedIn && to.name !== 'login') {
    next({ name: 'login' });
  } else {
    // if (user.value && user.value.abilities?.length && !user.value.is_admin && !user.value.is_moderator) {
    //   const parentPath = to.path.split('/')[1];
    //   if (parentPath) {
    //     const profilePath = config.profileMenu.find((p) => p.to === parentPath);
    //     if (profilePath) {
    //       next();
    //     }
    //
    //     const routeFilter = config.sidebarMenu.filter(
    //       (m) =>
    //         Object.values(user.value.abilities).find((a) => a.name === m.ability) ||
    //         m.default,
    //     );
    //
    //     const acceptRoute = routeFilter.find((r) => r.to === parentPath);
    //     if (!acceptRoute && parentPath !== '404') {
    //       next({ name: '404' });
    //     }
    //   }
    //   next();
    // }
    next();
  }
});

export default router;
