export default {
  state: () => ({
    activeSelect: null,
  }),

  getters: {
    getActiveSelect(state) {
      return state.activeSelect;
    },
  },

  mutations: {
    SET_ACTIVE_SELECT(state, item) {
      state.activeSelect = item;
    },
    UNSET_ACTIVE_SELECT(state) {
      state.activeSelect = null;
    },
  },
};
