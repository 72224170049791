export default [
  {
    path: '/servers',
    name: 'servers',
    component: () => import(/* webpackChunkName: "servers" */ '@/views/Servers/ServersPage'),
    meta: {
      title: 'Серверы',
    },
    redirect: { name: 'servers.index' },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "servers" */ '@/views/Servers/ServersIndex.vue'),
        name: 'servers.index',
      },
      {
        path: '/servers/transactions',
        component: () => import(/* webpackChunkName: "servers" */ '@/views/Servers/TransactionsLayout.vue'),
        name: 'servers.transactions',
        meta: {
          title: 'Транзакции',
        },
      },
    ],
  },
];
