export default [
  {
    path: '/language',
    component: () => import(/* webpackChunkName: "language" */ '../../views/Language/LanguagePage'),
    name: 'language',
    meta: {
      title: 'Язык',
    },
  },
];
