<template>
  <div class="main">
    <BaseLoader v-if="getLoader"/>

    <router-view v-if="$route.name === 'login'"/>

    <template v-else-if="user">
      <AppBar
        class="h-24"
      >
        <AppBarSection>
          <Button
            :fill-mode="'flat'"
            :svg-icon="menuIcon"
            @click="expanded = !expanded"
          />
        </AppBarSection>

        <AppBarSection
          style="width: 180px; flex-shrink: 0;"
        >
          <router-link to="/">
            <img
              alt="logo"
              class="block w-full"
              src="@/assets/images/logo.svg"
            >
          </router-link>
        </AppBarSection>

        <AppBarSpacer :width="32"/>

        <AppBarSection>
          <span class="k-appbar-separator"></span>
        </AppBarSection>

        <AppBarSection>
          <LayoutHeader v-if="$route.name !== 'login'"/>
        </AppBarSection>
      </AppBar>

      <AppDrawer :expanded="expanded"/>
    </template>

    <div id="base-modal-teleport"></div>
  </div>
</template>

<script setup>
import BaseLoader from '@/components/base/BaseLoader.vue';
import LayoutHeader from '@/components/layout/LayoutHeader.vue';
import { AppBar, AppBarSection, AppBarSpacer } from '@progress/kendo-vue-layout';
import { Button } from '@progress/kendo-vue-buttons';
import { computed, watch } from 'vue';
import { menuIcon } from '@progress/kendo-svg-icons';
import { useStore } from 'vuex';
import '@vueform/multiselect/themes/default.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import '@vuepic/vue-datepicker/dist/main.css';
import AppDrawer from '@/components/AppDrawer.vue';
import { useDrawerMenu } from '@/assets/js/drawer/useDrawerMenu';

const store = useStore();
const getLoader = computed(() => store.getters.getLoader);
const user = computed(() => store.getters.user);
const { expanded } = useDrawerMenu();

watch(
  () => user.value,
  async (val) => {
    if (val) {
      await Promise.all(
        [
          store.dispatch('getEnums'),
        ],
      );
    }
  },
);
</script>