export const dateFormat = {
  dateOptions: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  dateOptionsYmdHis: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  dateOptionsYMD: {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  },
  dateOptionsYM: {
    month: 'numeric',
    year: 'numeric',
  },
  dateOptionsTime: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },

  getFormatDate(date) {
    const d = new Date(date);
    return d.toLocaleDateString('ru-ru', this.dateOptions);
  },
  getFormatDateYmdHis(date) {
    const d = new Date(date);
    return d.toLocaleDateString('sv-SE', this.dateOptionsYmdHis);
  },
  getFormatDateYMD(date) {
    const d = new Date(date);
    return d.toLocaleDateString('ru-ru', this.dateOptionsYMD);
  },
  getFormatDateYM(date) {
    const d = new Date(date);
    return d.toLocaleDateString('ru-ru', this.dateOptionsYM);
  },
  getFormatDateTime(date) {
    const d = new Date(date);
    return d.toLocaleTimeString('ru-ru', this.dateOptionsTime);
  },
};

export const menu = {
  findCategoryElement(parentId, array) {
    for (const item of array) {
      if (item.id === parentId) return item;
      if (item.children) {
        const child = this.findCategoryElement(parentId, item.children);
        if (child) return child;
      }
    }
  },
};