export default [
  {
    path: '/news',
    component: () => import(/* webpackChunkName: "news-section" */ '../../views/News/News'),
    name: 'news',
    meta: {
      title: 'Новости',
    },
  },
];
