import * as yup from 'yup';
import config from '@/config';
import { mapGetters } from 'vuex';

export const userMixin = {
  computed: {
    ...mapGetters(['userChannels', 'userVideos', 'userPlaylists']),

    getUserName() {
      let from = null;
      if ('account' in this) {
        from = this.account;
      } else if ('user' in this) {
        from = this.user;
      } else {
        from = this.item;
      }
      const { name, first_name, last_name, login } = from;
      if (name) {
        return name;
      } else if (first_name && last_name) {
        return `${first_name} ${last_name}`;
      } else if (first_name || last_name) {
        return first_name || last_name;
      } else {
        return login;
      }
    },

    getUserLogin() {
      const { login } = this.account || this.user || this.item;
      return login;
    },

    getUserImage() {
      let obj = null;
      if ('user' in this) obj = this.user;
      else if ('account' in this) obj = this.account;

      else obj = this.item;

      if (obj?.avatar?.png) {
        const images = Object.values(obj?.avatar?.png);
        if (images.length) {
          for (let i = 0; i < images.length; i++) {
            const width = parseInt(images[i].split('/').pop().split('x').shift(),10);
            if (width >= this.width) {
              return images[i];
            }
          }
        }
      }
      return require('@/assets/images/user-placeholder.svg');
    },

    getChannelsCount() {
      if (this.userChannels) {
        return Object.keys(this.userChannels).length;
      } else {
        return 0;
      }
    },
    getVideosCount() {
      if (this.userVideos) {
        return Object.keys(this.userVideos).length;
      } else {
        return 0;
      }
    },
    getPlaylistCount() {
      if (this.userPlaylists) {
        return Object.keys(this.userPlaylists).length;
      } else {
        return 0;
      }
    },
  },
};

export const newsMixin = {
  data() {
    return {
      newsTypes: {
        0: 'Пользователи',
        1: 'Владельцы',
        2: 'Модераторы',
      },
      newsPublished: {
        false: {
          title: 'Черновик',
          icon: 'time',
          color: '#5985A6',
        },
        true: {
          title: 'Опубликовано',
          icon: 'done-circle',
          color: '#44A766',
        },
      },
    };
  },
};

export const parentMenu = {
  methods: {
    findCategoryElement(findId, array) {
      for (const item of array) {
        if (item.id === findId) return item;
        if (item.children) {
          const child = this.findCategoryElement(findId, item.children);
          if (child) return child;
        }
      }
    },

    setMenu(item, parent) {
      if (!item.active) item.active = false;
      item.name = item.name.ru;
      if (item?.children?.length) {
        item.children.forEach((child) => {
          let item = this.findCategoryElement(child.id, parent);
          if (!item.active) item.active = false;
          this.setMenu(child, parent);
        });
      }
      return parent;
    },
    checkString: (current, target) => current.toLowerCase().includes(target.toLowerCase()),
    findCategoryElementByString(name, array) {
      this.dataMenu = this.recursive(name, array);
    },
    recursive(name, array) {
      return array.filter(item => {
        item.children = this.recursive(name, item.children);
        return item?.children?.length || this.checkString(item.name, name);
      });
    },
  },
};

export const categoriesMixin = {
  data() {
    const loginFormSchema = yup.object().shape({
      link: yup.string().
        required('Обязательное поле').
        matches(/^[a-z0-9-_/]+$/, 'Некорректный формат ссылки'),
    });
    return {
      loginFormSchema,
      fullForm: {},
      languages: config.languages,
      languagesCodes: [],
      menu: [],
      loaded: false,
      errors: [],
      activeLanguage: 'ru',
      parent_id: null,
      link: '',
      disabled: true,
      setBlur: false,
      isFirstLevelCategory: false,
      isNavigatorCategory: false,
    };
  },

  computed: {
    getDisabled() {
      if (!this.link || this.disabled) return true;
      return !!Object.values(this.fullForm).some(el => el.name === '');
    },
    getTooltip() {
      if (this.getDisabled) {
        const errors = [];
        if (!this.link) {
          errors.push('Введите ссылку');
        }
        if (Object.values(this.fullForm).some(el => el.name === '')) {
          errors.push('Введите названия для всех языков');
        }
        if (errors.length) {
          let content = '<ul>';
          errors.forEach(err => {
            content += `<li>- ${err}</li>`;
          });
          content += '</ul>';
          return {
            content,
            html: true,
            theme: 'info-tooltip',
          };
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  },
  methods: {
    validate(field) {
      this.setBlur = true;
      this.link = this.link.toLowerCase();
      yup.reach(this.loginFormSchema, 'link').validate(this.link).then(() => {
        this.errors[field] = '';
        this.disabled = false;
      }).catch(err => {
        this.errors[field] = err.message;
        this.disabled = true;
      });
    },
  },
};

export const dateMixin = {
  data() {
    return {
      dateOptions: {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    };
  },
  computed: {
    getCreatedAtFormat() {
      const d = new Date(this.item.created_at);
      return d.toLocaleDateString('ru-ru', this.dateOptions);
    },
    getCreatedAtFormatTablet(date) {
      const d = new Date(date);
      return d.toLocaleDateString('ru-ru', this.dateOptions);
    },
    getUpdatedAtFormat() {
      const d = new Date(this.item.updated_at);
      return d.toLocaleDateString('ru-ru', this.dateOptions);
    },
  },
};

export const clipboardMixin = {
  methods: {
    async copyToClipboard(data, key, needCopy) {
      if (needCopy) {
        await navigator.clipboard.writeText(data);
        this.$toast.open({
          message: `${key} cкопирован в буфер обмена`,
          type: 'info',
          duration: 1000,
        });
      }
    },
  },
};
