import { http } from '@/plugins/axios';

export default {
  async getAll(payload) {
    const { data } = await http.post(
      '/api/v1/access/index',
      payload,
    );
    return data;
  },
  async getByID(id) {
    const { data } = await http.post('/api/v1/access/get-user', {
      id,
    });
    return data;
  },
  async create(payload) {
    const { data } = await http.post(
      '/api/v1/access/store',
      payload,
    );
    return data;
  },
  async update(payload) {
    const { data } = await http.post(
      '/api/v1/access/update',
      payload,
    );
    return data;
  },
  async delete(ids) {
    const { data } = await http.post('/api/v1/access/delete', {
      ids,
    });
    return data;
  },

  async getPermissions() {
    const { data } = await http.post(
      '/api/v1/access/get-permissions',
    );
    return data;
  },
  async generateUniqueID() {
    const { data } = await http.post(
      '/api/v1/access/generate-uniqueId',
    );
    return data;
  },
};
