import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async getRegistrations(payload) {
    const { data } = await http.get('/api/v1/dashboard/registration', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    },
    );
    return data;
  },

  async getRegistrationMethods(payload) {
    const { data } = await http.get('api/v1/dashboard/registration-method', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    },
    );
    return data;
  },

  async getSmsByDay(payload) {
    const { data } = await http.get('/api/v1/dashboard/sms/by-day', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    },
    );
    return data;
  },

  async getSmsByIp(payload) {
    const { data } = await http.get('/api/v1/dashboard/sms/by-ip', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    },
    );
    return data;
  },

  async getSmsByRoutes(payload) {
    const { data } = await http.get('/api/v1/dashboard/sms/by-routes', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    },
    );
    return data;
  },

  async getSmsToCallRatio(payload) {
    const { data } = await http.get('/api/v1/dashboard/sms/sms-to-call-ratio', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    },
    );
    return data;
  },

};

/*
Отправленные смс за период с группировкой по дням.
GET /api/v1/dashboard/sms/by-day

Отправленные смс за период с группировкой по IP адресу.
GET /api/v1/dashboard/sms/by-ip

Отправленные смс за период с группировкой по точке вызова.
GET /api/v1/dashboard/sms/by-routes

Соотношение отправленных смс и звонков за период.
GET /api/v1/dashboard/sms/sms-to-call-ratio

Требуемые параметры: date_from, date_to (формат timestamp)
 */
