export default [
  {
    path: '/video-data',
    name: 'video-data',
    component: () => import(/* webpackChunkName: "video-data" */ '@/views/VideoData/VideoDataPage.vue'),
    meta: {
      title: 'Файлы',
    },
    redirect: { name: 'video-data.index' },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "video-data" */ '@/views/VideoData/VideoDataFiles.vue'),
        name: 'video-data.index',
      },
      {
        path: '/video-data/comments',
        component: () => import(/* webpackChunkName: "video-data" */ '@/views/VideoData/CommentsLayout.vue'),
        name: 'video-data.comments',
        meta: {
          title: 'Комментарии',
        },
        children: [
          {
            path: ':id',
            component: () => import(/* webpackChunkName: "video-data" */ '@/views/VideoData/_comment_id.vue'),
            name: 'comment_id',
          },
        ],
      },
    ],
  },
];
