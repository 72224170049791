import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async getAll(payload) {
    const { data } = await http.get('/api/v1/streams', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    },
    );
    return data;
  },

  async getOne(id) {
    const { data } = await http.get(`/api/v1/streams/${id}`, { withLoader: false });
    return data;
  },

  async getStreamLogs(id, payload) {
    const { data } = await http.get(`api/v1/streams/${id}/logs`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    },
    );
    return data;
  },

  async generateKey(id) {
    const { data } = await http.post(`api/v1/streams/${id}/key-generate`, { withLoader: false });
    return data;
  },

  async restoreKey(id) {
    const { data } = await http.post(`api/v1/streams/${id}/restore`, { withLoader: false });
    return data;
  },

};