import config from '../../config';

export default {
  state: () => ({
    adminFilter: {
      page: 1,
      per_page: config.admin_moderators.per_page,
      search: null,
      role: config.admin_moderators.role,
      sort: {
        field: null,
        value: null,
      },
    },
    permissionsFilter: {
      page: 1,
      per_page: config.permissions.per_page,
      search: null,
    },
    usersFilter: {
      page: 1,
      per_page: config.users.per_page,
      search: null,
    },
    fakesFilter: {
      search: null,
      page: 1,
      per_page: config.users.per_page,
      fakes: true,
    },
    transferredFilter: {
      search: null,
      page: 1,
      per_page: config.users.per_page,
      fakes: true,
      fake_status: 3,
    },
    complainsFilter: {
      search: null,
      page: 1,
      per_page: config.users.per_page,
    },
    verifyFilter: {
      search: null,
      page: 1,
      per_page: config.verify.per_page,
    },
    // filesFilter: {
    //   search: null,
    //   page: 1,
    //   per_page: config.files.per_page,
    // },
    videoDataFilesFilter: {
      search: null,
      page: 1,
      per_page: config.videoDataFiles.per_page,
    },
    videoDataCommentsFilter: {
      search: null,
      page: 1,
      per_page: config.videoDataComments.per_page,
    },
    categoriesFilter: {
      search: null,
      sort: null,
      parent_id: null,
    },
    downloadsFilter: {
      search: null,
      page: 1,
      per_page: config.downloadsFilter.per_page,
    },
    analyticsFilter: {
      per_page: config.analyticsFilter.per_page,
    },
    domainsFilter: {
      per_page: config.domains.per_page,
      page: 1,
    },
    dateRangeFilter: [],
  }),

  getters: {
    adminFilter(state) {
      return state.adminFilter;
    },
    permissionsFilter(state) {
      return state.permissionsFilter;
    },
    usersFilter(state) {
      return state.usersFilter;
    },
    fakesFilter(state) {
      return state.fakesFilter;
    },
    transferredFilter(state) {
      return state.transferredFilter;
    },
    complainsFilter(state) {
      return state.complainsFilter;
    },
    verifyFilter(state) {
      return state.verifyFilter;
    },
    // filesFilter(state) {
    //   return state.filesFilter;
    // },
    videoDataFilesFilter(state) {
      return state.videoDataFilesFilter;
    },
    videoDataCommentsFilter(state) {
      return state.videoDataCommentsFilter;
    },
    newsFilter(state) {
      return state.newsFilter;
    },
    categoriesFilter(state) {
      return state.categoriesFilter;
    },
    downloadsFilter(state) {
      return state.downloadsFilter;
    },
    analyticsFilter(state) {
      return state.analyticsFilter;
    },
    domainsFilter(state) {
      return state.domainsFilter;
    },
    dateRangeFilter(state) {
      return state.dateRangeFilter;
    },
  },

  mutations: {
    setAdminFilter(state, filter) {
      state.adminFilter = filter;
    },
    setPermissionsFilter(state, filter) {
      state.permissionsFilter = filter;
    },
    setUsersFilter(state, filter) {
      state.usersFilter = filter;
    },
    SET_FAKES_FILTER(state, filter) {
      state.fakesFilter = filter;
    },
    SET_TRANSFERRED_FILTER(state, filter) {
      state.transferredFilter = filter;
    },
    SET_VERIFY_FILTER(state, filter) {
      state.verifyFilter = filter;
    },
    SET_CATEGORIES_FILTER(state, filter) {
      state.categoriesFilter = {
        ...state.categoriesFilter,
        ...filter,
      };
    },
    SET_DOWNLOADS_FILTER(state, filter) {
      state.downloadsFilter = filter;
    },
    SET_ANALYTICS_FILTER(state, filter) {
      state.analyticsFilter = filter;
    },
    SET_DATE_RANGE_FILTER(state, filter) {
      state.dateRangeFilter = filter;
    },
  },
};
