export default {
  state: () => ({
    search: '',
  }),

  getters: {
    getSearchValue(state) {
      return state.search;
    },
  },

  mutations: {
    SET_SEARCH(state, value) {
      state.search = value;
    },
  },
};
