<template>
  <header class="header flex justify-between">
    <slot name="content">
      <div class="header__content w-3/4 flex justify-between">
        <div class="datepicker">
          <Datepicker
            v-model="dateRange"
            :format="formatFunc"
            :range="true"
            select-text="Выбрать"
            cancel-text="Отмена"
            locale="ru"
            placeholder="Выбрать период"
          />
        </div>
        <BaseHeaderSearch/>
      </div>
    </slot>
    <div class="profile header__profile ml-auto pl-12 flex items-center">
      <HeaderIcon icon="message"/>
      <HeaderIcon icon="notifications"/>

      <div class="profile__info flex">
        <BaseButton
          class="color-gray hover-effect-2 flex"
          @click.stop.prevent="TOGGLE_PROFILE_MENU"
        >
          <img
            :width="width"
            height="40"
            alt="Фото профиля"
            class="rounded-full"
            :src="getUserImage"
          >
          <BaseIcon
            v-if="!getProfileMenu"
            name="arrow-down"
          />
          <BaseIcon
            v-if="getProfileMenu"
            name="arrow-down"
            style="transform: rotate(180deg)"
          />
        </BaseButton>

        <div
          v-show="getProfileMenu"
          class="profile__info-menu py-2 active rounded"
        >
          <div
            v-for="(item, index) in config.profileMenu"
            :key="index"
          >
            <ProfileMenuItem
              v-if="showMenuItem(item)"
              :icon="item.icon"
              :title="item.name"
              :to="item.to"
            />
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import HeaderIcon from '@/components/layout/Header/HeaderIcon';
import ProfileMenuItem from '@/components/layout/Header/ProfileMenuItem';
import BaseButton from '@/components/base/BaseButton';
import BaseIcon from '@/components/base/BaseIcon';
import { mapGetters, mapMutations } from 'vuex';
import { userMixin } from '@/mixins';
import BaseHeaderSearch from '@/components/base/BaseHeaderSearch.vue';
import Datepicker from '@vuepic/vue-datepicker';

export default {
  name: 'layout-header',

  components: {
    Datepicker,
    BaseHeaderSearch,
    BaseButton,
    BaseIcon,
    HeaderIcon,
    ProfileMenuItem,
  },

  mixins: [userMixin],

  data() {
    return {
      width: 40,
    };
  },

  computed: {
    ...mapGetters({
      getProfileMenu: 'getProfileMenu',
      account: 'user',
    }),
  },
  methods: {
    ...mapMutations(['TOGGLE_PROFILE_MENU']),
    showMenuItem(item) {
      if (this.account?.is_admin) return true;
      if (item.show === 'admin' && this.account?.is_moderator) return false;
      return true;
    },
  },
};
</script>

<script setup>

import { computed } from 'vue';
import { useQuery } from '@/compositions/useQuery';
import { DATE_FROM_QUERY, DATE_TO_QUERY } from '@/assets/js/const';
import { format } from 'date-fns';
import { useRoute } from 'vue-router';

const { setQuery } = useQuery();
const route = useRoute();

const formatFunc = ([dateFrom, dateTo]) => {
  const dateFromFormatted = format(dateFrom, 'dd.MM.yyyy HH:mm');
  const dateToFormatted = format(dateTo, 'dd.MM.yyyy HH:mm');

  return `${dateFromFormatted} - ${dateToFormatted}`;
};

const dateRange = computed({
  get() {
    const query = { ...route.query };
    const fromDate = new Date(Number.parseFloat(query[DATE_FROM_QUERY]));
    const toDate = new Date(Number.parseFloat(query[DATE_TO_QUERY]));
    return [
      fromDate,
      toDate,
    ];
  },
  set(newValue) {
    let fromDate = undefined;
    let toDate = undefined;
    if (newValue) {
      fromDate = new Date(newValue[0]).valueOf();
      toDate = new Date(newValue[1]).valueOf();
    }
    setQuery({
      [DATE_FROM_QUERY]: fromDate,
      [DATE_TO_QUERY]: toDate,
    });
  },
});
</script>

<style lang="scss" scoped>
.header {
  left: 240px;
  padding: 24px;
  position: fixed;
  top: 0;
  width: calc(100% - 240px);
  z-index: 50;
  background-color: #f8f9fa;

  .profile {
    flex-shrink: 0;

    &__info {
      position: relative;

      &-menu {
        background: var(--color-white);
        box-shadow: var(--box-shadow-block);
        display: none;
        min-width: 156px;
        position: absolute;
        right: 0;
        top: calc(100% + 7px);

        &.active {
          display: block;
        }
      }
    }
  }
}
</style>
