import accountsRepository from '@/repositories/accountsRepository';
import config from '@/config';

export default {
  state: () => ({
    allAccounts: null,
    fakesAccounts: null,
    transferredAccounts: null,
  }),

  getters: {
    getAllAccounts(state) {
      return state.allAccounts;
    },
    getFakesAccounts(state) {
      return state.fakesAccounts;
    },
    getTransferredAccounts(state) {
      return state.transferredAccounts;
    },
  },

  mutations: {
    SET_ALL_ACCOUNTS(state, data) {
      state.allAccounts = data;
    },
    SET_FAKES_ACCOUNTS(state, data) {
      state.fakesAccounts = data;
    },
    SET_TRANSFERRED_ACCOUNTS(state, data) {
      state.transferredAccounts = data;
    },
    DELETE_ALL_ACCOUNTS(state) {
      state.allAccounts = null;
    },
    DELETE_FAKES_ACCOUNTS(state) {
      state.fakesAccounts = null;
    },
    DELETE_TRANSFERRED_ACCOUNTS(state) {
      state.transferredAccounts = null;
    },
  },

  actions: {
    async setAllAccounts(state) {
      const query = await accountsRepository.getAll(config.users);
      if (query?.meta?.total) {
        state.commit('SET_PAGES', query.meta);
        if (query.data.length) {
          state.commit('SET_ALL_ACCOUNTS', query.data);
          return;
        }
      }
      state.commit('SET_ALL_ACCOUNTS', null);
    },
    async setFakesAccounts(state) {
      const query = await accountsRepository.getAll(config.fakeUsers);
      if (query?.meta?.total) {
        state.commit('SET_PAGES', query.meta);
        if (query.data.length) {
          state.commit('SET_FAKES_ACCOUNTS', query.data);
          return;
        }
      }
      state.commit('SET_FAKES_ACCOUNTS', null);
    },
    async setTransferredAccounts(state) {
      const query = await accountsRepository.getAll(
        config.transferredUsers,
      );
      if (query?.meta?.total) {
        state.commit('SET_PAGES', query.meta);
        if (query.data.length) {
          state.commit('SET_TRANSFERRED_ACCOUNTS', query.data);
          return;
        }
      }
      state.commit('SET_TRANSFERRED_ACCOUNTS', null);
    },
    async toggleAccount(state, { userId, status }) {
      return status
        ? await accountsRepository.enableUser(userId)
        : await accountsRepository.disableUser(userId);
    },
  },
};
