class DrawerMenu {
    _items;
    constructor(items) {
        this._items = items;
    }
    get items() {
        return this._items;
    }
    isParent(id) {
        return this.findItem(id)?.isParent;
    }
    select(id) {
        this.deselectAll();
        this.findItem(id).setSelected(true);
    }
    deselect(id) {
        this.findItem(id).setSelected(false);
    }
    expand(id) {
        if (!this.isParent(id)) {
            return;
        }
        this._items
            .filter(item => item.parentId === id)
            .forEach(i => i.setVisible(true));
        this.findItem(id).setExpanded(true);
    }
    collapse(id) {
        if (!this.isParent(id)) {
            return;
        }
        this._items
            .filter(item => item.parentId === id)
            .forEach(i => i.setVisible(false));
        this.findItem(id).setExpanded(false);
    }
    collapseAll() {
        this._items
            .filter(i => this.isParent(i.id))
            .forEach(i => this.collapse(i.id));
    }
    toggleExpanded(id) {
        this.findItem(id).expanded ? this.collapse(id) : this.expand(id);
    }
    deselectAll() {
        this._items.forEach(item => item.setSelected(false));
    }
    findItem(id) {
        return this._items?.find(item => item.id === id);
    }
    getState() {
        return this._items.filter(i => i.expanded).map(i => i.id);
    }
    setState(arr) {
        arr.forEach(id => {
            this.expand(id);
        });
    }
}
export { DrawerMenu };
