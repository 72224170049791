export default [
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ '../../views/Profile/ProfilePage'),
    name: 'profile',
    meta: {
      title: 'Аккаунт',
    },
  },
];
