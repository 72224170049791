import { http } from '@/plugins/axios';
import qs from 'qs';

// Feedbacks
const path = '/api/v1/feedbacks';

export default {
  async getAll(payload) {
    const { data } = await http.get(`${path}`, {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },

  async getOne(id) {
    const { data } = await http.get(`${path}/${id}`);
    return data;
  },

  async updateOne(id, payload) {
    const { data } = await http.put(`${path}/${id}`, payload);
    return data;
  },

  // Complaints
  async toggleBlock(id, operation) {
    const { data } = await http.post(`/api/v1/videos/${id}/${operation}`);
    return data;
  },

  async getVideosWithReports(payload) {
    const { data } = await http.get('/api/v1/videos/with-reports',
      {
        params: payload,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
        withLoader: false,
      },
    );
    return data;
  },

  // Complains-reason

  async getVideoComplainsReasons(payload) {
    const { data } = await http.get('/api/v1/video-reports/reasons', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getVideoComplainReason(id) {
    const { data } = await http.get(`/api/v1/video-reports/reasons/${id}`);
    return data;
  },

  async createVideoComplainReason(payload) {
    const { data } = await http.post('/api/v1/video-reports/reasons', payload);
    return data;
  },

  async updateVideoComplainReason(id, payload) {
    const { data } = await http.put(`/api/v1/video-reports/reasons/${id}`, payload);
    return data;
  },

  async deleteVideoComplainReason(id) {
    const { data } = await http.delete(`/api/v1/video-reports/reasons/${id}`);
    return data;
  },

  // Video-reports
  async getVideoComplains(payload) {
    const { data } = await http.get('/api/v1/video-reports', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getVideoComplain(id) {
    const { data } = await http.get(`/api/v1/video-reports/${id}`, { withLoader: false });
    return data;
  },

  async updateVideoComplain(id, payload) {
    const { data } = await http.put(`/api/v1/video-reports/${id}`, payload, { withLoader: false });
    return data;
  },

  async deleteVideoComplain(id) {
    const { data } = await http.delete(`/api/v1/video-reports/${id}`, { withLoader: false });
    return data;
  },

  // Copyright-reports
  async getCopyrightComplains(payload) {
    const { data } = await http.get('/api/v1/copyright-reports', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getCopyrightComplain(id) {
    const { data } = await http.get(`/api/v1/copyright-reports/${id}`, { withLoader: false });
    return data;
  },

  async updateCopyrightComplain(id, payload) {
    const { data } = await http.put(`/api/v1/copyright-reports/${id}`, payload, { withLoader: false });
    return data;
  },

  async deleteCopyrightComplain(id) {
    const { data } = await http.delete(`/api/v1/copyright-reports/${id}`, { withLoader: false });
    return data;
  },
};

