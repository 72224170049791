import filesRepository from '@/repositories/filesRepository';
import { dateFormat } from '@/plugins/functions';
import config from '@/config';

const { merge } = require('lodash');

export default {
  state: () => ({
    files: null,
    activeFile: null,
  }),

  getters: {
    getFiles(state) {
      return state.files;
    },
    getActiveFile(state) {
      return state.activeFile;
    },
  },

  mutations: {
    SET_FILES(state, data) {
      data = data.map(el => {
        return merge(el, {
          icons: [
            {
              name: 'edit',
              action: 'edit',
            },
            {
              name: 'delete-outline',
              action: 'delete',
            },
          ],
          image: {
            src: el?.cover?.png?.['130x72'] ?? require('@/assets/images/attachment-1.png'),
            height: 68,
            width: 68,
          },
          headerName: {
            title: el?.name ?? 'Без имени',
            size: 16,
          },
          info: [
            {
              title: 'Дата загрузки',
              text: dateFormat.getFormatDate(el.created_at),
            },
            {
              title: 'ID Файла',
              text: el.id + '',
            },
            {
              title: 'Название',
              text: el?.name ?? '-',
            },
            {
              title: 'Описание',
              text: el?.description ?? '-',
            },
          ],
        });
      });
      state.files = data;
    },
    SET_FILES_MODAL(state, acc) {
      state.activeFile = acc;
    },
    UNSET_FILES_MODAL(state) {
      state.activeFile = null;
    },
  },

  actions: {
    async setFiles(state) {
      const query = await filesRepository.getAll(config.files);
      if (query?.meta?.total) {
        state.commit('SET_PAGES', query.meta);
        if (query.data.length) {
          state.commit('SET_FILES', query.data);
          return;
        }
      }
      state.commit('SET_FILES', null);
    },
    async deleteFile(state) {
      // TODO: delete file
      console.log('Need delete method in API');
    },
    async saveFile(state, { id, payload }) {
      const query = await filesRepository.updateOne(id, payload);
      console.log(query);
    },
  },
};
