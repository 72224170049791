export default [
  {
    path: '/accounts',
    name: 'accounts',
    component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/AccountsLayout.vue'),
    meta: {
      title: 'Аккаунты',
    },
    // redirect: { name: 'accounts.index' },
  },

  {
    path: '/accounts/real',
    name: 'accounts.real',
    component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/AccountsLayout.vue'),
    meta: {
      title: 'Аккаунты',
    },
    // redirect: { name: 'accounts.index' },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/AccountsList.vue'),
        name: 'accounts.index',
      },
      {
        path: ':id',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/_id.vue'),
        name: 'accounts.id',
      },
      {
        path: ':id/channel/:channel',
        component: () => import('../../views/Accounts/_channel.vue'),
        name: 'accounts.channel',
      },
      {
        path: ':id/channel/:channel/playlist/:playlist',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/_playlist.vue'),
        name: 'accounts.playlist',
      },
    ],
  },

  {
    path: '/accounts/fake',
    component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/AccountsLayout.vue'),
    name: 'fake',
    meta: {
      title: 'Фэйковые аккаунты',
    },
    children: [
      {
        path: '',
        name: 'fake.accounts',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/FakeAccountsList.vue'),
      },
      {
        path: ':id',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/_fakeId.vue'),
        name: 'fake.id',
      },
      {
        path: ':id/channel/:channel',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/_channel.vue'),
        name: 'fake.accounts.channel',
      },
      {
        path: ':id/channel/:channel/playlist/:playlist',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/_playlist.vue'),
        name: 'fake.accounts.playlist',
      },
    ],
  },

  {
    path: '/accounts/send',
    component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/AccountsLayout.vue'),
    name: 'send',
    meta: {
      title: 'Переданные аккаунты',
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/TransferredAccountsList.vue'),
        name: 'send.accounts',
      },
    ],
  },

  {
    path: '/accounts/banned',
    component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/AccountsLayout.vue'),
    name: 'ban',
    meta: {
      title: 'Заблокированные аккаунты',
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/BannedAccountsList.vue'),
        name: 'banned.accounts',
      },
    ],
  },

  {
    path: '/accounts/deleted',
    component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/AccountsLayout.vue'),
    name: 'del',
    meta: {
      title: 'Удаленные аккаунты',
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "accounts" */ '../../views/Accounts/DeletedAccountsList.vue'),
        name: 'deleted.accounts',
      },
    ],
  },
];
