import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async getAll(payload) {
    const { data } = await http.get('/api/v1/content', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async add(payload) {
    const { data } = await http.post('/api/v1/content', payload, { withLoader: false });
    return data;
  },

  async getOne(id) {
    const { data } = await http.get(`/api/v1/content/${id}`, { withLoader: false });
    return data;
  },

  async edit(id, payload) {
    const { data } = await http.patch(`/api/v1/content/${id}`, payload, { withLoader: false });
    return data;
  },

  async delete(id) {
    const { data } = await http.delete(`/api/v1/content/${id}`, { withLoader: false });
    return data;
  },
};
