export default [
  {
    path: '/blocked-domains',
    component: () => import(/* webpackChunkName: "blocked-domains" */ '../../views/BlockedDomains/BlockedDomainsPage'),
    name: 'blocked-domains',
    meta: {
      title: 'Блокировка доменов',
    },
  },
];
