import authRepository from '../../repositories/authRepository';
// import channelsRepository from '@/repositories/channelsRepository';
import playlistsResository from '@/repositories/playlistsRepository';

export default {
  state: () => ({
    user: null,
    status: {
      loggedIn: false,
    },
    userChannels: null,
    userPlaylists: null,
    userVideos: null,
  }),

  getters: {
    user(state) {
      return state.user;
    },
    userChannels(state) {
      return state.userChannels;
    },
    userPlaylists(state) {
      return state.userPlaylists;
    },
    userVideos(state) {
      return state.userVideos;
    },
  },

  mutations: {
    SET_USER(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    SET_USER_CHANNELS(state, channels) {
      state.userChannels = channels;
    },
    SET_USER_PLAYLISTS(state, playlists) {
      state.userPlaylists = playlists;
    },
    SET_USER_VIDEOS(state, videos) {
      state.userVideos = videos;
    },
    LOGIN_FAILURE(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    LOGOUT(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
  },

  actions: {
    async fetchUser({ commit }) {
      if (localStorage.getItem('isLoggedIn')) {
        const res = await authRepository.getSelf();

        // if (!res.data.is_admin) {
        //   const abilities = await authRepository.getAbility(res.data.id);
        //   res.data.abilities = { ...abilities.data.abilities };
        // }
        if (res.data) {
          console.info('Authenticated user:', res.data.email);
          commit('SET_USER', res.data);
          // const channels = await channelsRepository.getAll({ account_id: res.data.id });
          // if (channels.data) {
          //   commit('SET_USER_CHANNELS', channels.data);
          // }
          if (res.data?.is_admin) {
            const playlists = await playlistsResository.getAll(
              { account_id: res.data.id });
            if (playlists.data) {
              commit('SET_USER_PLAYLISTS', playlists.data);
            }
          }
        } else {
          localStorage.removeItem('isLoggedIn');
          console.info('User is not authenticated');
          commit('LOGIN_FAILURE', null);
        }
      } else {
        console.info('User is not authenticated');
        commit('LOGIN_FAILURE', null);
      }
    },
    logout({ commit }) {
      localStorage.removeItem('isLoggedIn');
      commit('LOGOUT');
      requestAnimationFrame(() => window.location.reload());
    },
  },
};
