import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async getAllReceivers(payload) {
    const { data } = await http.get('api/v1/app/donate/receivers', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async blockUser(payload) {
    const { data } = await http.post('api/v1/app/donate/user/block', payload, { withLoader: false });
    return data;
  },

  async getAllTransactions(payload) {
    const { data } = await http.get('api/v1/app/donate/transactions', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getTransaction(id) {
    const { data } = await http.get(`api/v1/app/donate/transaction/${id}`, { withLoader: false });
    return data;
  },
};

// Для фронта (админка)
// точки для работы с получателями:
//   GET-api/v1/donate/receivers - точка просмотра аккаунтов в системе донатов.
//   POST-api/v1/app/donate/user/block - точка блокировки пользователя получать донаты и вывода средств. У вызывающего должно быть открыто право на обновление пользователя.
//   точки для работы с транзакциями:
//   GET-api/v1/donate/transactions - точка просмотра поступлений денежных средств, с фильтрами, поиском, сортировкой, пагинацией.
//
//   GET-api/v1/donate/transaction/{id} - точка детального просмотра отдельного поступления (транзакции) с историей.