import abilitiesRepository from '@/repositories/abilitiesRepository';
import accessRepository from '@/repositories/accessRepository';
import accountsRepository from '@/repositories/accountsRepository';
import authRepository from '@/repositories/authRepository';
import categoriesRepository from '@/repositories/categoriesRepository';
import channelsRepository from '@/repositories/channelsRepository';
import complainsRepository from '@/repositories/complainsRepository';
import domainsRepository from '@/repositories/domainsRepository';
import filesRepository from '@/repositories/filesRepository';
import newsRepository from '@/repositories/newsRepository';
import playlistsRepository from '@/repositories/playlistsRepository';
import reportsRepository from '@/repositories/reportsRepository';
import testRepository from '@/repositories/testRepository';
import uploadsRepository from '@/repositories/uploadsRepository';
import userRepository from '@/repositories/userRepository';
import verificationsRepository from '@/repositories/verificationsRepository';
import verifyRepository from '@/repositories/verifyRepository';
import { moderationRepository } from '@/repositories/moderationRepository';
import settingsRepository from '@/repositories/settingsRepository';
import monetizationRepository from '@/repositories/monetizationRepository';
import serversRepository from '@/repositories/serversRepository';
import paymentsRepository from '@/repositories/paymentsRepository';
import videoNoticesRepository from '@/repositories/videoNoticesRepository';
import noticesRepository from '@/repositories/noticesRepository';
import commentsRepository from '@/repositories/commentsRepository';
import streamsRepository from '@/repositories/streamsRepository';
import templateRepository from '@/repositories/templateRepository';
import strikesRepository from '@/repositories/strikesRepository';
import rolesRepository from '@/repositories/rolesRepository';
import notificationsRepository from '@/repositories/notificationsRepository';
import smsRepository from '@/repositories/smsRepository';
import analyticsRepository from '@/repositories/analyticsRepository';
import globalRepository from '@/repositories/globalRepository';
import aboutRepository from '@/repositories/aboutRepository';
import donateRepository from '@/repositories/donateRepository';
import aiClassificationRepository from '@/repositories/aiClassificationRepository';

export const useRepository = () => {
  return {
    abilities: abilitiesRepository,
    access: accessRepository,
    accounts: accountsRepository,
    auth: authRepository,
    categories: categoriesRepository,
    channels: channelsRepository,
    complains: complainsRepository,
    domains: domainsRepository,
    files: filesRepository,
    news: newsRepository,
    playlists: playlistsRepository,
    reports: reportsRepository,
    test: testRepository,
    uploads: uploadsRepository,
    user: userRepository,
    verifications: verificationsRepository,
    verify: verifyRepository,
    moderation: moderationRepository,
    settings: settingsRepository,
    monetization: monetizationRepository,
    servers: serversRepository,
    payments: paymentsRepository,
    videoNotices: videoNoticesRepository,
    notices: noticesRepository,
    comments: commentsRepository,
    streams: streamsRepository,
    template: templateRepository,
    strikes: strikesRepository,
    roles: rolesRepository,
    notifications: notificationsRepository,
    sms: smsRepository,
    analytics: analyticsRepository,
    global: globalRepository,
    about: aboutRepository,
    donate: donateRepository,
    aiClassification: aiClassificationRepository,
  };
};
