// use global $format(v, 'duration')
export function formatDuration(ms) {
  const rawSeconds = ms ? Number(ms / 1000) : 0;

  const hours = Math.floor(rawSeconds / 3600);
  const formattedHours = Number(hours) < 10 ? `0${hours}` : hours;

  const minutes = Math.floor((rawSeconds / 60) % 60);
  const formattedMinutes = Number(minutes) < 10 ? `0${minutes}` : minutes;

  const seconds = Math.floor(rawSeconds % 60);
  const formattedSeconds = Number(seconds) < 10 ? `0${seconds}` : seconds;

  const minutesSeconds = `${formattedMinutes}:${formattedSeconds}`;
  return hours ? `${formattedHours}:${minutesSeconds}` : minutesSeconds;
}
// use global $format(v, 'bigNumberShort')
export function formatBigNumber(v) {
  v = v ? Number(v) : 0;
  if (v < 1000) return v;
  else if (v < 1000000) return `${(v / 1000).toFixed(1)}K`;
  else if (v < 1000000000) return `${(v / 1000000).toFixed(1)}M`;
  else return `${Math.floor(v / 1000000000)}B`;
}

export function msToRange(milliseconds) {
  const seconds = milliseconds / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;

  if (seconds < 60) {
    return `${Math.floor(seconds)} сек.`;
  } else if (minutes < 60) {
    return `${Math.floor(minutes)} мин.`;
  } else {
    const bigNumber = formatBigNumber(hours);
    if (Math.floor(minutes % 60)) {
      return `${Math.floor(bigNumber)} ч. ${Math.floor(minutes % 60)} мин.`;
    } else {
      return `${Math.floor(bigNumber)} ч.`;
    }
  }
}