export default {
  namespaced: true,

  state: () => ({
    show: false,
    id: null,
    type: null,
    color: null,
    icon: null,
    timeout: null,
  }),

  mutations: {
    SHOW_SNACKBAR(
      state,
      { type = 'success', color, icon, timeout, title, text },
    ) {
      if (type) {
        const predefinedType = types.find((item) => item.name === type);
        state.color = predefinedType.color;
        state.icon = icon || predefinedType.icon;
        state.timeout = timeout || predefinedType.timeout;
        state.title = title || predefinedType.title;
        state.text = text || '';
      } else {
        state.color = color || defaultColor;
        state.icon = icon || defaultIcon;
        state.timeout = timeout || defaultTimeout;
        state.title = title || 'Title';
        state.text = text || '';
      }

      state.show = true;
      state.id = new Date().getTime();
    },

    HIDE_SNACKBAR(state) {
      state.show = false;
    },
  },

  actions: {
    showSnackbar({ commit }, options) {
      commit('SHOW_SNACKBAR', options);
    },
    hideSnackbar({ commit }) {
      commit('HIDE_SNACKBAR');
    },
  },
};

const defaultTimeout = 3000;
const defaultColor = 'green';
const defaultIcon = 'check-on';

const types = [
  {
    name: 'success',
    color: 'green',
    icon: 'check-on',
    timeout: 3000,
    title: 'Успешно!',
  },
  {
    name: 'info',
    color: 'blue',
    icon: 'article-outline',
    timeout: 4000,
    title: 'Информация',
  },
  {
    name: 'error',
    color: 'red',
    icon: 'exclamation-circle-outline',
    timeout: 5000,
    title: 'Что-то пошло не так',
  },
  {
    name: 'warning',
    color: 'yellow',
    icon: 'exclamation-outline',
    timeout: 5000,
    title: 'Внимание!',
  },
];
