import { useSocket } from '@/compositions/socket/useSocket';
import { onBeforeMount, onBeforeUnmount } from 'vue';

const waitSocketConnected = (socket) => {
  return new Promise((resolve) => {
    let interval = null;
    if (socket.connected) {
      return resolve();
    } else {
      interval = setInterval(() => {
        if (socket.connected) {
          clearInterval(interval);
          resolve();
        }
      }, 300);
      socket.once('connected', () => {
        if (interval) {
          clearInterval(interval);
        }
        return resolve();
      });
    }
  });
};

export const useSocketListen = (events, callback, addOnCreate = true, id = 0) => {
  const socket = useSocket();
  
  if (typeof events === 'string') events = [events];
  
  const subscribe = () => {
    events.forEach(event => {
      waitSocketConnected(socket)
        .then(() => socket.on(event, callback));
    });
  };

  const unsubscribe = () => {
    events.forEach(event => {
      socket.off(event, callback);
    });
  };
  
  onBeforeMount(() => addOnCreate && subscribe());
  onBeforeUnmount(unsubscribe);
  
  return {
    subscribe,
    unsubscribe,
  };
};
