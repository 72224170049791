import axios from 'axios';
import store from '@/store';
import { useToast } from 'vue-toast-notification';
import authRepository from '@/repositories/authRepository';
import router from '../router/index';

const toast = useToast({
  position: 'top-right',
  duration: 5000,
});

const http = axios.create({
  baseURL: window.location.origin,
  withCredentials: true,
});

http.interceptors.request.use((config) => {
  // если параметр передан и он false, то выключить (по умолчанию будет включен)
  if (Object.hasOwn(config, 'withLoader') && config?.withLoader === false) {
    store.dispatch('setLoader', false);
  } else {
    store.dispatch('setLoader', true);
  }
  return config;
});

http.interceptors.response.use(
  (response) => {
    if (response.request.responseURL.includes('?page=')) {
      window.scrollTo(0, 0);
    }
    store.dispatch('setLoader', false);
    return response;
  },

  (error) => {
    store.dispatch('setLoader', false);

    switch (error.response.status) {
    case 302:
      console.info(error.response.data.error);
      break;

    case 419:
      authRepository.csrf().then(() => {
        return axios.request(error.config)
          .catch((error) => {
            if (error.response.status === 401) {
              localStorage.removeItem('isLoggedIn');
              router.push('login');
              store.commit('SET_USER', null);
            } else {
              toast.error(`${error.code} ${error.response.status}<br/>${error?.response?.data?.message}`);
            }
          });
      });
      break;

    case 401:
      localStorage.removeItem('isLoggedIn');
      router.push('login');
      store.commit('SET_USER', null);
      toast.error(`${error.code} ${error.response.status}<br/>${error?.response?.data?.message}`);
      break;

    default:
      console.error('Ошибка:', error);
      break;
    }

    return Promise.reject(error);
  },
);

export { http };

