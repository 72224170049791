import { http } from '@/plugins/axios';
import qs from 'qs';

const path = '/api/v1/verifications';

export default {
  async getAll(payload) {
    const { data } = await http.get(`${path}`, { params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      withLoader: false,
    });
    return data;
  },

  async getOne(id) {
    const { data } = await http.get(`${path}/${id}`, { withLoader: false });
    return data;
  },

  // payload: check_status, 'comment'
  async updateOne(id, payload) {
    const { data } = await http.patch(`${path}/${id}`, payload);
    return data;
  },

  async deleteOne(id) {
    const { data } = await http.delete(`${path}/${id}`);
    return data;
  },
};
