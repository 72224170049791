import { http } from '@/plugins/axios';
import qs from 'qs';

export default {
  async postNotices(payload) {
    const { data } = await http.post('api/v1/video-notices', payload);
    return data;
  },
  async getNotices(payload) {
    const { data } = await http.get('api/v1/video-notices', {
      params: payload,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    return data;
  },
  async getNoticesId(id) {
    const { data } = await http.get(`api/v1/video-notices/${id}`);
    return data;
  },
  async deleteNotices(id) {
    const { data } = await http.delete(`api/v1/video-notices/${id}`);
    return data;
  },
  async updateNotices(id) {
    const { data } = await http.put(`api/v1/video-notices/${id}`);
    return data;
  },
};