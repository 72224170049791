export default [
  {
    path: '/categories',
    component: () => import(/* webpackChunkName: "categories" */ '../../views/Categories/CategoriesPage.vue'),
    name: 'categories',
    meta: {
      title: 'Категории',
    },
    redirect: { name: 'categories.index' },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "categories" */ '../../views/Categories/CategoriesIndex.vue'),
        name: 'categories.index',
        meta: {
          title: 'Общие категории',
        },
      },
      {
        path: 'navigator',
        component: () => import(/* webpackChunkName: "categories" */ '../../views/Categories/CategoriesNavigator.vue'),
        name: 'categories.navigator',
        meta: {
          title: 'Категории навигатора',
        },
      },
    ],
  },
];
