import { io } from 'socket.io-client';
import { http } from '@/plugins/axios';

let _IO = null;

export function useSocket() {
  if (!_IO) {
    _IO = io(process.env.VUE_APP_SOCKET_SERVER, {
      'transports': [
        'websocket',
      ],
      path: '/api/events/socket',
      timeout: 5 * 60 * 1000,
    });
  }

  return _IO;
}

export function useSocketChannel(chat) {
  const socket = _IO || useSocket();

  const fetchAuth = () => {
    const body = {
      'channel_name': chat,
      'socket_id': socket.id,
    };
    http
      .post('/api/broadcasting/auth', body, { withLoader: false })
      .catch(e => console.error('Ошибка авторизации сокета:', e));
  };

  const subscribe = () => {
    if (socket.connected) {
      fetchAuth();
    } else {
      socket.on('connect', fetchAuth);
    }
  };

  const unsubscribe = () => {
    socket.emit('leave-room', { room: chat });
  };

  return [
    subscribe,
    unsubscribe,
  ];
}
