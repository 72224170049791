import newsRepository from '@/repositories/newsRepository';
import config from '@/config/index';

export default {
  state: () => ({
    news: null,
    activeOneNews: null,
  }),

  getters: {
    getNews(state) {
      return state.news;
    },
    getActiveOneNews(state) {
      return state.activeOneNews;
    },
  },

  mutations: {
    SET_NEWS(state, res) {
      state.news = res ? res : null;
    },
    SET_NEWS_MODAL(state, oneNews) {
      state.activeOneNews = oneNews;
    },
    UNSET_NEWS_MODAL(state) {
      state.activeOneNews = null;
    },
  },

  actions: {
    // FIXME: NEED API DATA
    async setNews(state) {
      const query = await newsRepository.getAll(config.news);
      if (query.data.length) {
        state.commit('SET_PAGES', query.meta);
        state.commit('SET_NEWS', query.data);
        return;
      }
      this.commit('SET_NEWS', null);
    },
  },
};
