import router from '@/router';

export default {
  getUrlPage() {
    if ('page' in router.currentRoute.value.query) {
      const page = parseInt(router.currentRoute.value.query.page);
      if (isNaN(page)) return 1;
      if (page < 1) return 1;
      return page;
    } else {
      return 1;
    }
  },
  addParamsToLocation: (params) => {
    let url = new URL(window.location);
    for (let key in params) {
      if (params[key] === null) {
        url.searchParams.delete(key);
      } else {
        url.searchParams.set(key, params[key]);
      }
    }
    history.pushState({}, '', url);
  },
};
