const DRAWER_MENU_ITEMS = [
  {
    text: 'Главная',
    icon: 'k-i-home',
    data: {
      path: '/',
    },
    permission: 'default',
  },

  {
    separator: true,
  },

  {
    text: 'Контент',
    icon: 'k-i-film',
    expanded: false,
    permission: 'default',
    items: [
      {
        text: 'Модерация',
        icon: 'bi-shield-check',
        data: {
          path: '/downloads',
        },
        permission: 'view moderations',
      },

      {
        text: 'Все видео',
        icon: 'k-i-file-data',
        data: {
          path: '/video-data',
        },
        permission: 'view videos',
      },
      {
        text: 'Каналы',
        icon: 'bi-person-video2',
        data: {
          path: '/channels',
        },
        permission: 'view channels',
      },
      {
        text: 'Плейлисты',
        icon: 'bi-file-earmark-music-fill',
        data: {
          path: '/playlists',
        },
        permission: 'view playlists',
      },
      {
        text: 'Стримы',
        icon: 'bi-broadcast',
        data: {
          path: '/streams',
        },
        permission: 'view streams',
      },

    ],
  },

  {
    separator: true,
  },

  {
    text: 'Настройки',
    icon: 'k-i-gear',
    expanded: false,
    permission: 'view settings',
    items: [
      {
        text: 'Видеоконтент',
        icon: 'k-i-video-external',
        data: {
          path: '/settings/player',
        },
        permission: 'view settings',
      },
      {
        text: 'Трансляции',
        icon: 'bi-broadcast',
        data: {
          path: '/settings/streams',
        },
        permission: 'view settings',
      },
      {
        text: 'Уведомления',
        icon: 'k-i-notification',
        data: {
          path: '/settings/notifications',
        },
        permission: 'view settings',
      },
      {
        text: 'Безопасность',
        icon: 'bi-shield-fill-check',
        data: {
          path: '/settings/security',
        },
        permission: 'view settings',
      },
      // {
      //   text: 'Иноагенты',
      //   icon: 'bi-incognito',
      //   data: {
      //     path: '/settings',
      //   },
      //   permission: 'view settings',
      // },
      {
        text: 'Поиск',
        icon: 'k-i-zoom',
        data: {
          path: '/settings/search',
        },
        permission: 'view settings',
      },
      {
        text: 'Главная страница',
        icon: 'bi-house-gear',
        data: {
          path: '/settings/main',
        },
        permission: 'view settings',
      },
      {
        text: 'Субтитры',
        icon: 'k-i-subtitles',
        data: {
          path: '/settings/subtitles',
        },
        permission: 'view settings',
      },
      {
        text: 'Категории жалоб',
        icon: 'bi-exclamation-diamond-fill',
        data: {
          path: '/settings/complaintsCategories',
        },
        permission: 'view settings',
      },
      {
        text: 'Шаблоны',
        icon: 'k-i-file-txt',
        data: {
          path: '/settings/template',
        },
        permission: 'view settings',
      },
      {
        text: 'О нас',
        icon: 'bi-info-circle',
        data: {
          path: '/settings/about',
        },
        permission: 'view settings',
      },
      {
        text: 'Роли и полномочия',
        icon: 'bi-person-workspace',
        data: {
          path: '/settings/roles',
        },
        permission: 'view settings',
      },
      {
        text: 'Конвертер',
        icon: 'bi-cpu',
        data: {
          path: '/settings/converter',
        },
        permission: 'view settings',
      },
      {
        text: 'SMS',
        icon: 'bi-envelope',
        data: {
          path: '/settings/sms',
        },
        permission: 'view settings',
      },
      {
        text: 'SEO',
        icon: 'bi-clipboard-data-fill',
        data: {
          path: '/settings/seo',
        },
        permission: 'view settings',
      },
    ],
  },

  {
    separator: true,
  },

  {
    text: 'Посетители',
    icon: 'k-i-user',
    expanded: false,
    permission: 'default',
    items: [
      {
        text: 'Аккаунты',
        icon: 'bi-person-vcard',
        data: {
          path: '/accounts/real',
        },
        permission: 'view accounts',
      },
      {
        text: 'Верификация',
        icon: 'bi-person-check-fill',
        data: {
          path: '/verify',
        },
        permission: 'view verifications',
      },
      {
        text: 'Жалобы',
        icon: 'bi-cloud-lightning-fill',
        data: {
          path: '/complains/video',
        },
        permission: ['view video reports', 'view copyright reports'],
      },
      {
        text: 'Монетизация',
        icon: 'bi-currency-exchange',
        data: {
          path: '/monetization/contracts',
        },
        permission: 'view monetizations',
      },
      {
        text: 'Донаты',
        icon: 'bi-piggy-bank',
        data: {
          path: '/donate',
        },
        permission: 'view donate',
      },
      {
        text: 'Уведомления',
        icon: 'bi-bell',
        data: {
          path: '/notifications',
        },
        permission: 'default',
      },
      {
        text: 'СМС',
        icon: 'bi-envelope',
        data: {
          path: '/sms',
        },
        permission: 'default',
      },
    ],
  },

  {
    separator: true,
  },

  {
    text: 'Категории',
    icon: 'bi-tags-fill',
    data: {
      path: '/categories',
    },
    permission: 'view categories',
  },

  {
    text: 'Выдача доступа',
    icon: 'k-i-unlock',
    data: {
      path: '/access',
    },
    permission: 'view users',
  },
  {
    text: 'Страйки',
    icon: 'bi-lightning-fill',
    data: {
      path: '/strikes',
    },
    permission: 'view strikes',
  },
  {
    text: 'Аналитика',
    icon: 'bi-pie-chart-fill',
    data: {
      path: '/analytics',
    },
    permission: 'default',
  },
  {
    text: 'Новости',
    icon: 'bi-newspaper',
    data: {
      path: '/news',
    },
    permission: 'view news',
  },
  {
    text: 'Блокировка доменов',
    icon: 'k-i-cancel-outline',
    data: {
      path: '/blocked-domains',
    },
    permission: 'view banned domains',
  },
  {
    text: 'Серверы',
    icon: 'bi-hdd-rack-fill',
    data: {
      path: '/servers',
    },
    permission: 'view servers',
  },
];

export default DRAWER_MENU_ITEMS;