<template>
  <div :class="{ 'lds-dual-ring-wrapper': !inside, 'inside-wrapper': inside }">
    <div class="lds-dual-ring"></div>
  </div>
</template>

<script>
export default {
  name: 'base-loader',
  props: {
    inside: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped>
.inside-wrapper {
  min-height: 80vh;
}
.lds-dual-ring-wrapper {
  top: 90px;
  left: 225px;
  width: calc(100vw - 225px);
  height: calc(100vh - 90px);
  position: fixed;
  backdrop-filter: blur(3px);
  z-index: 99999;
}

.lds-dual-ring {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 80px;
  height: 80px;
  z-index: 1000;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--color-info);
  border-color: var(--color-info) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
