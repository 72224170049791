export default {
  languages: [
    {
      code: 'ru',
      abbr: 'rus',
    },
    {
      code: 'en',
      abbr: 'eng',
    },
    {
      code: 'kk',
      abbr: 'kaz',
    },
  ],
  sidebarMenu: [
    {
      name: 'Главная',
      to: 'admin',
      icon: 'home-outline',
      ability: 'home',
      default: true,
      visible: true,
      backendKey: 'default',
      permission: 'default',
    },
    {
      name: 'Аккаунты',
      to: 'accounts.index',
      icon: 'user-outline',
      ability: 'accounts',
      default: false,
      visible: true,
      backendKey: '',
      permission: 'view users',
    },
    {
      name: 'Выдача доступов',
      to: 'access',
      icon: 'lock-outline',
      ability: 'access',
      default: false,
      visible: true,
      backendKey: '',
      permission: 'view accounts',
    },
    {
      name: 'Монетизация',
      to: 'monetization',
      icon: 'monetization',
      default: false,
      ability: 'monetization',
      visible: true,
      backendKey: '',
      permission: 'view monetizations',
    },
    {
      name: 'Запросы верификации',
      to: 'verify',
      icon: 'user-outline-frame',
      ability: 'verify',
      default: false,
      visible: true,
      backendKey: 'verification',
      permission: 'view verifications',
    },
    {
      name: 'Жалобы',
      to: 'complains',
      icon: 'sad-outline',
      default: false,
      ability: 'complains',
      visible: true,
      backendKey: 'complains',
      permission: 'view video reports',
    },
    {
      name: 'Стримы',
      to: 'streams.index',
      icon: 'stream',
      default: false,
      ability: 'streams',
      visible: true,
      backendKey: 'streams',
      permission: 'view streams',
    },
    {
      name: 'Общие данные по видео',
      to: 'video-data',
      icon: 'copy-outline',
      ability: 'video-data',
      default: false,
      visible: true,
      backendKey: '',
      permission: 'view videos',
    },
    {
      name: 'Страйки',
      to: 'strikes',
      icon: 'strike',
      ability: 'strikes',
      default: false,
      visible: true,
      backendKey: 'strikes',
      permission: 'view strikes',
    },
    // {
    //   name: 'Файлы',
    //   to: 'files',
    //   icon: 'copy-outline',
    //   ability: 'files',
    //   default: false,
    //   visible: true,
    // },
    {
      name: 'Видео на модерацию',
      to: 'downloads',
      icon: 'upload-outline',
      default: false,
      ability: 'downloads',
      visible: true,
      backendKey: 'moderation',
      permission: 'view moderations',
    },
    {
      name: 'Аналитика',
      to: 'analytics',
      icon: 'analytics-outline',
      default: false,
      ability: 'analytics',
      visible: true,
      backendKey: '',
      // permission: 'default',
    },
    {
      name: 'Настройки',
      to: 'settings',
      icon: 'settings-outline',
      default: true,
      ability: 'settings',
      visible: true,
      backendKey: '',
      permission: 'view settings',
    },
    {
      name: 'Категории',
      to: 'categories',
      icon: 'category',
      default: false,
      ability: 'category',
      visible: true,
      backendKey: '',
      permission: 'view categories',
    },
    {
      name: 'Новости',
      to: 'news',
      icon: 'news',
      default: true,
      ability: 'news',
      visible: true,
      backendKey: '',
      permission: 'view news',
    },
    {
      name: 'Блокировка доменов',
      to: 'blocked-domains',
      icon: 'blocked',
      default: true,
      ability: 'blocked-domains',
      visible: true,
      backendKey: '',
      permission: 'view banned domains',
    },
    {
      name: 'Аналитика модераторов',
      to: 'moderator-analytics',
      icon: 'moderator-analytics',
      default: true,
      ability: 'moderator-analytics',
      visible: true,
      backendKey: '',
      // permission: 'default',
    },
    {
      name: 'Серверы',
      to: 'servers',
      icon: 'servers',
      default: true,
      ability: 'servers',
      visible: true,
      backendKey: '',
      permission: 'view servers',
    },
  ],
  moderatorMenu: [
    {
      name: 'Загрузки',
      to: 'downloads',
      icon: 'upload-outline',
      default: false,
      ability: 'downloads',
      visible: true,
    },
  ],
  profileMenu: [
    {
      name: 'Аккаунт',
      to: 'profile',
      icon: 'user-outline',
    },
    {
      name: 'Язык: Русский',
      to: 'language',
      icon: 'language-outline',
    },
    {
      name: 'Настройки',
      to: 'settings',
      icon: 'settings-outline',
      show: 'admin',
    },
    {
      name: 'Выйти',
      to: 'logout',
      icon: 'logout-outline',
    },
  ],
  statusesVerified: {
    0: {
      title: 'Не решено',
      icon: 'status-0',
    },
    1: {
      title: 'Проверяется',
      icon: 'status-1',
    },
    2: {
      title: 'Пройдена',
      icon: 'status-2',
    },
    3: {
      title: 'Получено',
      icon: 'status-3',
    },
    4: {
      title: 'На повторной верификации',
      icon: 'status-4',
    },
    5: {
      title: 'Все',
    },
  },
  statuses_upload: {
    0: 'Не решено',
    1: 'В работе',
    2: 'Закрыто',
    3: 'Обрабатывается',
    4: 'Получено',
    5: 'Все',
  },
  children_content: {
    0: 'Видео для детей',
    1: 'Видео только для тех, кому есть 18',
    2: 'Нет',
  },
  statuses_uploaded: {
    0: 'Загружены',
    1: 'Не загружены',
  },
  user_type: {
    1: 'Физическое лицо',
    2: 'Юридическое лицо',
  },
  roles: {
    moderator: 1,
    admin: 2,
  },
  admin_moderators: {
    per_page: 10,
    role: 1,
  },
  // files: {
  //   per_page: 12,
  //   page: 1,
  //   sort: {
  //     field: 'name',
  //     direction: 'asc',
  //   },
  // },
  permissions: {
    per_page: 15,
  },
  users: {
    per_page: 30,
    sort: {
      field: 'created_at',
      direction: 'desc',
    },
    page: 1,
    filters: {
      fake_status: [null],
    },
  },
  fakeUsers: {
    per_page: 30,
    page: 1,
    sort: {
      field: 'created_at',
      direction: 'desc',
    },
    filters: {
      fake_status: [1, 2],
    },
  },
  transferredUsers: {
    per_page: 30,
    page: 1,
    sort: {
      field: 'created_at',
      direction: 'desc',
    },
    filters: {
      fake_status: [3],
    },
  },
  videoDataFiles: {
    per_page: 30,
    page: 1,
    sort: {
      field: 'date',
      direction: 'asc',
    },
    filters: {
      video_data_type: [1],
    },
  },
  videoDataComments: {
    per_page: 30,
    page: 1,
    filters: {
      video_data_type: [2],
    },
  },
  categories: {
    sort: {
      field: 'name',
      direction: 'asc',
    },
  },
  news: {
    per_page: 30,
    page: 1,
    sort: {
      field: 'id',
      direction: 'desc',
    },
  },
  downloadsFilter: {
    per_page: 20,
  },
  fakeStatuses: {
    1: 'Ожидает передачи',
    2: 'Запрос на передачу отправлен',
    3: 'Аккаунт передан',
  },
  analyticsFilter: {
    per_page: 20,
  },
  verify: {
    per_page: 20,
  },
  playlistsFilter: {
    search: null,
    page: 1,
    per_page: 30,
    sort: null,
    order: null,
    account_id: null,
    channel_id: null,
  },
  domains: {
    per_page: 30,
    page: 1,
    sort: {
      field: 'server_name',
      direction: 'asc',
    },
  },
  complains: {
    per_page: 30,
    page: 1,
  },
};
