import { http } from '@/plugins/axios';

export default {
  /*
   * search - string
   * page - integer
   * limit - integer
   */
  async getAll() {
    const { data } = await http.get('/api/v1/abilities');
    return data.data;
  },

  /*
   * name - string
   */
  async addAbility(payload) {
    const { data } = await http.post('/api/v1/abilities/', payload);
    return data;
  },

  /*
   * id - integer
   */
  async getAbility(id) {
    const { data } = await http.get(`/api/v1/abilities/${id}`);
    return data;
  },

  /*
   * id - integer
   * name - string
   */
  async updateAbility(id, payload) {
    const { data } = await http.patch(`/api/v1/abilities/${id}`, payload);
    return data;
  },
  
  /*
   * id - integer
   */
  async deleteAbility(id) {
    const { data } = await http.delete(`/api/v1/accounts/${id}`);
    return data;
  },
};