import { createApp, h } from 'vue';
import store from './store';
import router from './router';
import App from './App.vue';
import customConfig from './config';
// @ts-ignore
import history from '@/plugins/history';
import FloatingVue from 'floating-vue';
import { createI18n } from 'vue-i18n';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import './plugins/axios';
import './assets/scss/common.scss';
import './index.css';
import 'floating-vue/dist/style.css';
// @ts-ignore
import format from '@/plugins/format';
import './registerServiceWorker';
const app = createApp({
    render: () => h(App),
});
const i18n = createI18n({});
app.use(i18n);
format(app);
app.config.globalProperties.config = customConfig;
app.config.globalProperties.history = history;
app.config.globalProperties.$snackbar = (options) => store.commit('snackbar/SHOW_SNACKBAR', options);
app.component('app-content', App);
app.use({
    install(app) {
        app.config.globalProperties.$externalMethods = {
            set: (method, func) => (this.methods[method] = func),
            call: (method, ...args) => this.methods[method](...args),
        };
    },
});
app.directive('modalNoScroll', {
    mounted: (el) => {
        document.body.classList.add('k-overflow-y-hidden');
    },
    unmounted: (el) => {
        document.body.classList.remove('k-overflow-y-hidden');
    },
});
app.use(store);
app.use(router);
app.use(FloatingVue, {
    themes: {
        'info-tooltip': {
            $extend: 'tooltip',
            $resetCss: true,
        },
    },
});
app.use(ToastPlugin, {
    position: 'top-right',
    duration: 10000,
});
app.mount('#app');
