import { computed, ref } from 'vue';
import { DrawerMenu } from './DrawerMenu';
import DRAWER_MENU_ITEMS from '@/config/drawerMenuItems';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useRepository } from '@/compositions/useRepository';
import { useLocalStorage } from '@vueuse/core';
import DrawerMenuItem from '@/assets/js/drawer/DrawerMenuItem';
import { flow, partial } from 'lodash';

export const useDrawerMenu = () => {
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  const user = computed(() => store.getters.user);
  const { global } = useRepository();
  const getPermittedForCurrentUser = partial(filterPermitted, user.value);
  const getMenuItems = flow([getFlatMenu, getPermittedForCurrentUser, hideEmptyGroups]);
  const menuItems = getMenuItems(DRAWER_MENU_ITEMS);
  const menu = new DrawerMenu(menuItems);
  const menuState = useLocalStorage('main-menu', []);
  if (menuState.value?.length) {
    menu.setState(menuState.value);
  }
  const expanded = useLocalStorage('main-menu-expanded', true);
  const items = ref(menu.items);

  async function onSelect(ev) {
    const item = ev.itemTarget.props;
    const id = item.id;
    const isParent = menu.isParent(id);
    if (isParent) {
      menu.toggleExpanded(id);
      menuState.value = menu.getState();
    } else {
      await router.push(item.data);
    }
    items.value = [...menu.items];
  }

  // Собрать плоский массив элементов меню
  function getFlatMenu(items) {
    const result = [];
    items.forEach(item => {
      const newItem = new DrawerMenuItem(item);
      result.push(newItem);
      if (item?.items?.length) {
        item.items.forEach(
          child => result.push(new DrawerMenuItem(child, newItem.id),
          ));
      }
    });
    return result;
  }

  // отобрать пункты с разрешенным доступом
  function filterPermitted(user, items) {
    if (!user?.permissions?.length) return [];
    return items.filter( item => user?.permissions.some(a => item.separator || item.permission === 'default' || item.permission === a.name || checkPermissionInArray(item.permission, a.name)));
  }

  // проверить наличие разрешения, если разрешения для пункта меню представлены массивом, а не строкой
  function checkPermissionInArray(permissions, name) {
    if (!Array.isArray(permissions)) return false;
    return permissions.includes(name);
  }

  // спрятать группы без доступных пунктов
  function hideEmptyGroups(items) {
    return items.map((item) => {
      if (item.isParent) {
        const hasPermittedItems = !!items.filter(i => item.id === i.parentId).length;
        if (!hasPermittedItems) item.setVisible(false);
      }
      return item;
    });
  }

  // Ссылки на пункты поле 'text' меню для отображения /
  const menuMapping = {
    moderation: 'Модерация',
    verification: 'Верификация',
    complains: 'Жалобы',
  };

  // Обновить данные количества новых элементов
  const updateRecords = (newRecords) => {
    Object.keys(newRecords).forEach(
      record => {
        const id = menu.items.find(i => i.text === menuMapping[record])?.id;
        if (!id) return;
        menu.findItem(id).setNotifications(newRecords[record]);
      },
    );
    items.value = [...menu.items];
  };

  const countGroupNewItems = (id) => {
    if (!menu.isParent(id)) return;
    return menu.items
      .filter(item => item?.parentId === id)
      .reduce((acc, i) => i.notifications ? acc + i.notifications : acc, 0);
  };

  const selectActiveRoute = () => {
    const id = menu.items.find(i => i?.data?.path === route.path)?.id;
    if (id) {
      menu.select(id);
      const parentId = menu.findItem(id).parentId;
      if (parentId) {
        menu.expand(parentId);
      }
      items.value = [...menu.items];
    }
  };

  async function getNewRecords() {
    try {
      const newRecords = await global.getNewRecords();
      updateRecords(newRecords);
    } catch (e) {
      console.error('Ошибка при получении кол-ва новых записей:', e);
    }
  }

  return {
    items,
    menu,
    onSelect,
    updateRecords,
    menuMapping,
    countGroupNewItems,
    selectActiveRoute,
    getNewRecords,
    expanded,
  };
};